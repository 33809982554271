import {
  ExercisePaused,
  ExerciseResumed,
  ListenForReactEvent,
  ListenResumeExercise,
  NotifyReact,
  ReactToExerciseEventType,
  RemoveListener,
} from 'Phaser/EventBus';
import Phaser from 'phaser';

export default class PauseMenu extends Phaser.Scene {
  other: string | null = null;
  constructor() {
    super('PauseMenu');
  }
  create(): void {
    ListenForReactEvent(ListenResumeExercise(this.resume), this, true);
    //ListenForReactEvent("toggle-play-pause", this.resume, this, true);
    //ListenForReactEvent("resume-exercise", this.resume, this, true);
    this.events.once('shutdown', () => {
      RemoveListener(ReactToExerciseEventType.ResumeExercise);
    });
  }
  resume() {
    console.log('resume');
    NotifyReact(ExerciseResumed());
    this.scene.resume(this.other!);
    (this.scene.get(this.other!) as any).resume();
    this.scene.stop();
  }
  init({ other }: { other: string }): void {
    this.other = other;
    NotifyReact(ExercisePaused());
  }
}
