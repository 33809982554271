import {
  EventBus,
  NotifyReact,
  ReactToExerciseEventType,
  RemoveListener,
  ShowExercise,
} from "../EventBus";
import RoundedRect from "../GameObjects/RoundedRect";
import Note, { getNoteLengthInBeats, NoteData } from "../GameObjects/Note";
import Cursor, { IteratorPhaser, StaffNote } from "../GameObjects/Cursor";
import { Result } from "../GameObjects/Progress";
import ExerciseBase, { IObjective, IConfig } from "./ExerciseBase";
import Transition from "Phaser/Rendering/Transitions";
import TimeSignature from "Models/TimeSignature";
import { INoteGraphicsDatabase } from "./StaffExercise";
import IPlayable from "Phaser/GameObjects/Playable";

interface NoteSequenceObjective extends IObjective {
  scale?: number;
  notes: NoteData[];
}

export interface Config extends IConfig<NoteSequenceObjective> {
  tempo: number;
  paddingVertical?: number;
  paddingHorizontal?: number;
  transition?: "Wipe" | "Dissolve";
}

export function getDurationFromBeats(numBeats: number, tempo: number): number {
  const msPerBeat = (60 / tempo) * 1000;
  return numBeats * msPerBeat;
}

export default class NoteSequenceExercise
  extends ExerciseBase<NoteSequenceObjective, Config>
  implements INoteGraphicsDatabase
{
  table: Map<string, Note> = new Map();
  SPACE?: Phaser.Input.Keyboard.Key;

  instructionTimeout?: NodeJS.Timeout;
  // The configuration parameters with which to create the exercise.
  config?: Config;
  // The calculated available screen area excluding padding and other static game objects.
  playAreaSize?: { width: number; height: number; x: number; y: number };

  // State
  // The index of the current note that the player should play.
  currentNote?: number;
  cursor: Cursor | null = null;
  // Interval handle for the metronome.
  //clickIntervalHandle: Phaser.Time.TimerEvent | null = null;
  // Timer handle for delaying the start of the practice by a few beats.
  startTimerHandle: number | null = null;
  // Tween handle for the current progress indicator.
  tweenHandle?: Phaser.Tweens.Tween;
  /* Set to true if the player has hit the note slightly early.
   * It is reset to false automatically after the buffer window expires.
   * It is also reset to false once its been used to mark a note as held.
   * Must be used only via the `getEarlyHitBuffer()` and `setEarlyHitBuffer()` methods.
   */
  earlyHitBuffer: boolean = false;
  /// Timer handle for resetting the early hit buffer after the buffer window expires.
  earlyHitBufferResetTimer: Phaser.Time.TimerEvent | null = null;

  // Timer handles
  // Timer handle for marking a note as `missing` if the user takes too long to play it.
  lateHoldTimer: number | null = null;
  // Timer handle for marking a note as `early` if the user releases it early.
  earlyReleaseTimer: number | null = null;
  // Timer handle for marking a note as either `missed` if the user didnt hold it at all
  // during its entire duration, or `done` if the user held it for its entire duration.
  noteEndTimer: number | null = null;

  // Tween Handles
  cursorTweenHandle?: Phaser.Tweens.Tween;

  // Objects
  noteGraphics: Note[] = [];
  progressIndicators: RoundedRect[] = [];
  currentProgressIndicator: RoundedRect | null = null;
  results: (Result | undefined)[] = [];

  running: boolean = false;
  passFailIndicator?: Phaser.GameObjects.Image;

  started: boolean = false;

  constructor() {
    super("NoteSequenceExercise");
  }

  init(config: Config, forceLoad?: boolean) {
    this.config = config;
    this.currentNote = -1;
    this.results = [];
    this.noteGraphics = [];
    this.progressIndicators = [];
    this.config!.paddingHorizontal ??= 50;
    this.config!.paddingVertical ??= 50;
    this.updateObjective(forceLoad);
    super.init(config);
  }

  skipLoadTransition() {
    setTimeout(() => NotifyReact(ShowExercise()), 50);
    if (this.config!.transition === "Dissolve")
      this.fade(false, undefined, undefined, true);
    else this.wipe(false, undefined, undefined, true);
  }

  unload(): void {
    this.started = false;
    this.cursor?.destroy();
    this.cursor = null;
    this.running = false;
    for (let note of this.noteGraphics) note.destroy();
    if (this.instructionTimeout) clearTimeout(this.instructionTimeout);
    RemoveListener(ReactToExerciseEventType.UpdateObjective);
    super.unload();
  }

  midiOn(_data: any): void {
    if (this.started) {
      this.playNote();
    }
  }

  midiOff(_data: any): void {
    if (this.started) {
      this.releaseNote();
    }
  }

  transition(out?: boolean, onDone?: () => void, scope?: object) {
    if (!out) setTimeout(() => NotifyReact(ShowExercise()), 50);
    if (this.config!.transition === "Dissolve") {
      this.fade(out, onDone, scope);
    } else {
      this.wipe(out, onDone, scope);
    }
  }

  wipe(reverse?: boolean, onDone?: () => void, scope?: object, skip?: boolean) {
    const targets = this.cameras.main.getPostPipeline(
      this.config!.transition ?? "Wipe",
    ) as Transition;
    this.tweens.add({
      targets,
      progress: reverse ? 0.0 : 1.0,
      duration: skip ? 10 : 1000,
      onComplete: onDone,
      callbackScope: scope,
    });
  }

  fade(reverse?: boolean, onDone?: () => void, scope?: object, skip?: boolean) {
    if (reverse) {
      const targets = this.cameras.main.getPostPipeline(
        this.config!.transition ?? "Dissolve",
      ) as Transition;
      this.tweens.add({
        targets,
        progress: 0.0,
        duration: skip ? 10 : 1000,
        onComplete: onDone,
        callbackScope: scope,
      });
    } else {
      for (let i = 0; i < this.noteGraphics.length; i++) {
        if (skip) this.noteGraphics[i].setAlpha(1);
        else {
          if (i === this.noteGraphics.length - 1) {
            this.noteGraphics[i].fadeIn(600, i * 300, onDone, scope);
          } else {
            this.noteGraphics[i].fadeIn(600, i * 300);
          }
        }
      }
    }
  }

  updateObjective(forceLoad?: boolean): void {
    this.currentObjective = this.currentObjective
      ? this.currentObjective + 1
      : 0;
    this.instantiateObjects(forceLoad);
    if (forceLoad) this.skipLoadTransition();
    else setTimeout(() => this.transition(false), 500);
  }

  startExercise() {
    this.started = true;
    // reset every state variable and game objects to their initial states, in case of restarts
    this.currentNote = -1;
    if (this.cursor && !this.cursor.isActive()) {
      this.cursor.reset();
      this.cursor.waitForInput();
    }
    for (const note of this.noteGraphics) {
      note.reset();
    }
    for (let i = 0; i < this.results.length; i++) {
      this.results[i] = undefined;
    }
    if (this.passFailIndicator) this.passFailIndicator.destroy();
  }

  restart() {
    // this needs to be replaced with reinit.
    this.startExercise();
  }

  // Always use this function exclusively for setting the early hit buffer.
  // Do not mutate any related variables directly!
  setEarlyHitBuffer() {
    if (this.earlyHitBufferResetTimer) this.earlyHitBufferResetTimer.remove();
    this.earlyHitBuffer = true;
    this.earlyHitBufferResetTimer = this.time.addEvent({
      callback: () => (this.earlyHitBuffer = false),
      delay: 200,
      callbackScope: this,
    });
  }

  // Always use this function exclusively for clearing the early hit buffer.
  // Do not mutate any related variables directly!
  clearEarlyHitBuffer() {
    if (this.earlyHitBufferResetTimer) this.earlyHitBufferResetTimer.remove();
    this.earlyHitBuffer = false;
    this.earlyHitBufferResetTimer = null;
  }

  // This is the only way of accessing the early hit buffer without breaking it.
  // The buffer is cleared right after it is read since it should only be `consumed` once,
  // before it is set again.
  getEarlyHitBuffer(): boolean {
    const val = this.earlyHitBuffer;
    this.earlyHitBuffer = false;
    if (this.earlyHitBufferResetTimer) this.earlyHitBufferResetTimer.remove();
    this.earlyHitBufferResetTimer = null;
    return val;
  }

  onExerciseEnd() {
    this.running = false;
    /*if (this.clickIntervalHandle) {
      this.clickIntervalHandle.remove();
      this.clickIntervalHandle = null;
    }*/
    if (this.cursor) {
      if (this.cursorTweenHandle && this.cursorTweenHandle.isPlaying())
        this.cursorTweenHandle.stop();
      if (this.tweenHandle && this.tweenHandle.isPlaying())
        this.tweenHandle.stop();
    }
    // We consider the exercise a fail only if atleast one of the notes
    // were missed COMPLETELY. Every other scenario leads to a pass including notes
    // that were played off-time or released too early.
    console.debug(this.results);
    if (!this.checkNotes()) {
      this.failExercise();
    } else this.passObjective();
    // Emits an exercise end event to the external react context.
    EventBus.emit("exercise-ended");
  }

  // Should return true if all notes have a Successful result
  checkNotes = (): boolean => {
    console.debug(this.noteGraphics);
    this.noteGraphics.forEach((note) =>
      console.log("result: ", note.getResult()),
    );
    return (
      this.noteGraphics.find((note) => {
        const result = note.getResult();
        console.debug("note result: ", result);
        return (
          result === Result.Missed ||
          result === Result.OffTime ||
          result === undefined
        );
      }) === undefined
    );
  };

  onExerciseOver(): void {
    //this.cursor?.destroy();
    super.onExerciseOver();
  }

  passObjective(): void {
    this.started = false;
    this.cursor?.stop();
    super.passObjective(
      this.currentObjective === this.config?.objectives.length,
    );
  }

  onExerciseFail() {
    EventBus.emit("exercise-fail");
    this.restart();
  }

  instantiateObjects(forceLoad?: boolean) {
    const { notes, text, instructionWaitTime, scale } =
      this.config!.objectives[this.currentObjective!];
    /*if (instructionWaitTime && instructionWaitTime !== Infinity) {
      this.instructionTimeout = setTimeout(
        () => this.showInstruction(instructionText, 'Top'),
        time
      );
    }*/
    const dpr = window.devicePixelRatio;
    const paddingH = this.config!.paddingHorizontal ?? 50 * dpr;
    const paddingV = this.config!.paddingVertical ?? 50 * dpr;
    this.playAreaSize = {
      width: this.cameras.main.width - paddingH * 2,
      height: this.cameras.main.height - paddingV * 2,
      x: paddingH,
      y: paddingV,
    };
    /*const rect = this.add.rectangle(this.playAreaSize.x, this.playAreaSize.y, this.playAreaSize.width, this.playAreaSize.height, 0xff0000, 0.5);
    rect.setOrigin(0, 0);
    rect.setPosition(this.playAreaSize.x, this.playAreaSize.y);*/
    const yMid = this.playAreaSize.height / 2 + paddingV;
    // we want to centre-align the notes horizontally but not based on the entire length of the
    // note (the width of the note sprite + the width of the tail) so we have to offset the entire
    // group by the width of the final note.
    // A simple way of acheiving that is by simply adding the width of the last note twice
    // to the length of the entire thing before offsetting to make sure that the final tail
    // doesn't go beyond the width of the parent container.
    const numBeats = notes.reduce(
      (acc, curr) => acc + getNoteLengthInBeats(curr.length),
      0,
    );
    const lastNoteBeats = getNoteLengthInBeats(notes.last().length);
    const centreTails = notes.last().length === "whole";
    const beatWidth =
      this.playAreaSize.width /
      (numBeats! + (!centreTails ? lastNoteBeats : 0));
    const lastNoteWidth = beatWidth * lastNoteBeats;
    const offset = this.playAreaSize.x + (!centreTails ? lastNoteWidth : 0);
    const cursorWidth = 65 * dpr * (scale ?? 1);
    const cursorHeight = 375 * dpr * (scale ?? 1);
    notes.reduce((acc, note, idx) => {
      const width = beatWidth * getNoteLengthInBeats(note.length);
      const obj = new Note(
        this,
        acc,
        yMid + cursorHeight / 4,
        width,
        note.length,
        this.config!.tempo,
        "up",
        idx,
        //this.config!.transition === "Dissolve" // spawn all notes with alpha 0 for dissolve transition
      );
      obj.setScale(scale ?? 1);
      this.noteGraphics.push(obj);
      this.results.push(undefined);
      return acc + beatWidth * getNoteLengthInBeats(note.length);
    }, offset);
    for (let i = 0; i < this.noteGraphics.length - 1; i++) {
      const widthOffset = this.noteGraphics[i + 1].getWidth() * 0.55;
      this.noteGraphics[i].setProgressIndicatorMaxWidth(widthOffset);
    }
    /*const cursorData: StaffNote[] = this.noteGraphics.reduce(
      (acc: StaffNote[], note) => [
        ...acc,
        {
          timestamp:
            acc.length > 0
              ? acc.last().timestamp + acc.last().durationRelative
              : 0,
          position: {
            x: note.x - cursorWidth / 2,
            y: note.y - cursorHeight / 2,
          },
          durationInMs: note.getDurationMs(),
          durationRelative: getNoteLengthInBeats(note.noteLength) / 4.0,
          playable: note,
        },
      ],
      []
    );
    cursorData.push({
      position: {
        x: this.playAreaSize.width + this.playAreaSize.x,
        y: 0,
      },
      timestamp:
        cursorData.last().timestamp + cursorData.last().durationRelative,
      durationInMs: 0,
      durationRelative: 0,
    });*/
    this.cursor = new Cursor(
      this,
      offset - cursorWidth * 0.5,
      yMid - cursorHeight / 2,
      cursorWidth,
      cursorHeight,
      cursorWidth * 0.5,
      new TimeSignature(4, 4),
      this.config!.tempo,
      new IteratorPhaser([], ""),
    );
  }

  getNoteById(id: string): IPlayable | undefined {
    return undefined;
  }

  playNote() {
    console.log("playing note");
    this.setEarlyHitBuffer();
    if (!this.running) {
      const [timing] = this.cursor!.tryStart(this.onExerciseEnd, this);
      if (timing !== "perfect") {
        this.noteGraphics[0].shake(
          Math.min((60 / this.config!.tempo) * 1000, 200),
        );
        if (timing === "early") {
          this.showToast("Too early!");
        } else if (timing === "late") {
          this.showToast("Too late!");
        }
      } else {
        for (let i = 1; i < this.noteGraphics.length; i++) {
          this.noteGraphics[i].reset(true);
        }
        this.running = true;
        this.currentNote = 0;
        //this.noteGraphics[0].setFocus(true);
      }
    }
    if (this.running) {
      this.cursor?.onHoldNote();
    }
  }

  releaseNote() {
    this.clearEarlyHitBuffer();
    if (this.running) {
      this.cursor?.onReleaseNote();
    }
  }

  pauseExercise() {
    this.cursor?.pause();
    super.pauseExercise();
  }

  resume() {
    this.cursor?.resume();
    super.resume();
  }

  update(time: number) {
    this.cursor?.update(time);
    if (this.running) {
      if (this.currentNote! < this.noteGraphics.length - 1) {
        if (
          this.cursor!.isMoving &&
          this.cursor!.x + this.cursor!.width / 2 >
            this.noteGraphics[this.currentNote! + 1].x
        ) {
          this.currentNote! += 1;
        }
      }
    }
  }

  onNoteComplete(result: Result, idx: number) {
    console.log(result);
    this.results[idx] = result;
    if (idx === this.noteGraphics.length - 1) {
      this.onExerciseEnd();
    }
  }

  create() {
    // This event must be emitted before using any of the integrated phaser modules such as tweens, physics, etc.
    this.cameras.main.setPostPipeline(this.config!.transition || "Wipe");
    if (this.config!.transition === "Dissolve") {
      (this.cameras.main.getPostPipeline("Dissolve") as Transition).progress =
        1;
    }
    EventBus.off("update-objective");
    EventBus.on("update-objective", this.updateObjective, this);
    EventBus.emit("current-scene-ready", this);
  }

  stop() {
    this.scene.stop();
  }
}
