import Box from "@mui/material/Box";
import React from "react";
import { ReactSVG } from "react-svg";
import { Phrase, GrandStaff, StaffNote } from "Types/ExerciseData";

export interface PhraseObject {
  notes: StaffNote[];
  grandStaff: GrandStaff;
}

export interface PhraseData {
  url: string;
  musicData: Phrase;
  id: string;
}

interface Props {
  phrases: PhraseData[];
  callback: (phrases: Map<string, PhraseObject>) => void;
}

export default function PhraseSvgProcessor({ phrases, callback }: Props) {
  const result = React.useRef<Map<string, PhraseObject>>(new Map());

  /*React.useEffect(() => {
    if (count === phrases.length && result.current) {
      callback(result.current);
    }
  }, [count, phrases.length, callback]);*/

  const onLoaded = React.useCallback(
    (id: string, musicData: Phrase) => {
      const phraseSet = new Set();
      for (let phrase of phrases) {
        phraseSet.add(phrase.id);
      }
      const parent = document.getElementById("svg-processor-parent");
      const ref = document.getElementById(id);
      const parentRect = parent!.getBoundingClientRect();
      const wrapSvg = (
        content: string,
        x: number,
        y: number,
        width: number,
        height: number,
      ) => {
        const svg = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "svg",
        );
        svg.setAttribute("width", width.toString());
        svg.setAttribute("height", height.toString());
        svg.setAttribute("viewBox", `${x} ${y} ${width} ${height}`);
        svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        svg.innerHTML = content;
        return svg;
      };
      if (!ref) return;
      //musicData.notes.map((ts) => 0);
      const notes = musicData.notes
        .map((timestamp) =>
          timestamp.currentVoiceEntries
            .map((note) =>
              note.Notes.map((note) => {
                const svg = ref.querySelector(
                  `#${note.graphics[0].staveNote.id}`,
                );
                const { x, y, width, height } = svg!.getClientRects()[0];
                const _x = x - parentRect.x;
                const _y = y - parentRect.y;
                ref.removeChild(svg!);
                const wrapped = wrapSvg(svg!.outerHTML, _x, _y, width, height);
                document.body?.appendChild(wrapped);
                return {
                  timestamp: timestamp.currentTimestamp,
                  length: note.length,
                  id: note.graphics[0].staveNote.id,
                  svg: wrapped.outerHTML,
                  x: _x,
                  y: _y,
                  isRest: note.isRest,
                };
              }),
            )
            .flatMap((x) => x),
        )
        .flatMap((x) => x);
      const grandStaff = {
        svg: ref.outerHTML,
        id: ref.id,
        x: parentRect!.x,
        y: parentRect!.y,
      };
      result.current.set(ref.id, { grandStaff, notes });
      if (result.current.size === phraseSet.size) callback(result.current);
      else {
        console.log(phrases.length, result.current.size);
      }
    },
    [callback, phrases],
  );

  return (
    <Box sx={{ position: "absolute", background: "red", top: 0, left: 0 }}>
      {phrases.map(({ url, id, musicData }) => (
        <ReactSVG
          key={url}
          src={url}
          afterInjection={() => onLoaded(id, musicData)}
        />
      ))}
    </Box>
  );
}
