import {
  CurrentSceneReady,
  EventBus,
  ListenForReactEvent,
  ListenMidiOff,
  ListenMidiOn,
  ListenUpdateObjective,
  NotifyReact,
  ReactToExerciseEventType,
  RemoveListener,
  ShowExercise,
} from "../EventBus";
import Piano, {
  BaseNote,
  Note,
  getMidiNumberFromBaseNote,
  getMidiNumberFromNote,
} from "../GameObjects/Piano";
import Phaser from "phaser";
import Instruction from "Phaser/GameObjects/Instruction";
import ExerciseBase, { IObjective, IConfig } from "./ExerciseBase";
import Transition from "Phaser/Rendering/Transitions";

interface PianoObjective extends IObjective {
  text: string;
  target: "any" | Note;
  startAfter?: number;
}

export interface Config extends IConfig<PianoObjective> {
  start: Note;
  end: Note;
  objectives: PianoObjective[];
  transition?: "Slide" | "Dissolve";
  textPromptPosition: "Top" | "Bottom";
}

// Scene containing the interactive MIDI piano graphic and its related controllers.
export class PianoScene extends ExerciseBase<PianoObjective, Config> {
  // The piano gameobject
  piano?: Piano;
  // Current octave of the piano (for computer keyboard to MIDI mapping, like in GarageBand)
  octave?: number;

  instruction: Instruction | null = null;

  listening: boolean = false;
  timers: Phaser.Time.TimerEvent[] = [];

  constructor() {
    super({ key: "PianoScene" });
  }

  create() {
    NotifyReact(CurrentSceneReady(this));
  }

  init(config: Config) {
    this.currentObjective = 0;
    this.piano = new Piano(this, 0, 0, config.start, config.end);
    this.piano.setScale(0.12);
    this.piano.setPosition(
      -this.cameras.main.width / 2,
      this.cameras.main.height / 2 - 25 * window.devicePixelRatio,
    );
    if (config.transition === "Dissolve") {
      this.cameras.main.setPostPipeline("Dissolve");
    }
    console.log("transition:", config.transition);
    this.timers.push(
      this.time.delayedCall(
        500,
        () => {
          if (config.transition === "Dissolve") {
            this.piano!.x = this.cameras.main.width / 2;
            //this.cameras.main.fadeIn(1000);
            //this.cameras.main.alpha = 0;
            this.tweens.add({
              targets: this.cameras.main.getPostPipeline(
                "Dissolve",
              ) as Transition,
              progress: 1,
              duration: 1000,
            });
            //this.cameras.main.alpha;
          } else {
            this.tweens.add({
              targets: this.piano,
              x: this.cameras.main.width / 2,
              duration: 1000,
              ease: "Power1",
            });
          }
        },
        [],
        this,
      ),
    );
    console.log("scene: creating piano scene");
    this.timers.push(
      this.time.delayedCall(50, () => NotifyReact(ShowExercise())),
    );
    this.setupKeyboardMapping();
    this.octave = 1;
    ListenForReactEvent(ListenUpdateObjective(this.setObjective), this);
    super.init(config);
    //this.cameras.main.setBackgroundColor(colors.bg);
  }

  octaveUp() {
    if (this.octave! < this.piano!.highestOctave()) this.octave!++;
  }

  octaveDown() {
    if (this.octave! > this.piano!.lowestOctave()) this.octave!--;
  }

  addKeyToInputs(key: number) {
    return this.input.keyboard?.addKey(key);
  }

  setupKeyboardMapping() {
    const codes = Phaser.Input.Keyboard.KeyCodes;
    this.addKeyToInputs(codes.Z)?.addListener(
      "down",
      () => this.octaveDown(),
      this,
    );
    this.addKeyToInputs(codes.X)?.addListener(
      "down",
      () => this.octaveUp(),
      this,
    );
    const map = new Map<number, [BaseNote, number]>();
    map.set(codes.A, ["C", 0]);
    map.set(codes.W, ["C#", 0]);
    map.set(codes.S, ["D", 0]);
    map.set(codes.E, ["D#", 0]);
    map.set(codes.D, ["E", 0]);
    map.set(codes.F, ["F", 0]);
    map.set(codes.T, ["F#", 0]);
    map.set(codes.G, ["G", 0]);
    map.set(codes.Y, ["G#", 0]);
    map.set(codes.H, ["A", 0]);
    map.set(codes.U, ["A#", 0]);
    map.set(codes.J, ["B", 0]);
    map.set(codes.K, ["C", 1]);
    map.set(codes.O, ["C#", 1]);
    map.set(codes.L, ["D", 1]);
    map.set(codes.P, ["D#", 1]);
    map.set(codes.SEMICOLON, ["E", 1]);
    map.set(codes.COMMA, ["F", 1]);
    map.forEach(([baseNote, mod], keyCode) => {
      this.addKeyToInputs(keyCode)?.addListener(
        "down",
        () => {
          this.playNote(baseNote, mod);
        },
        this,
      );
      this.addKeyToInputs(keyCode)?.addListener(
        "up",
        () => {
          this.releaseNote(baseNote, mod);
        },
        this,
      );
    });
    /*ListenForReactEvent(
      //ListenMidiOn(({ key }: { key: number }) => this.piano!.onPressKey(key)),
      ListenMidiOn(({ key }: { key: number }) => this.midiOn(key)),
      this,
    );
    ListenForReactEvent(
      //ListenMidiOff(({ key }: { key: number }) =>
      //this.piano!.onReleaseKey(key),
      //),
      ListenMidiOff(({ key }: { key: number }) => this.midiOff(key)),
      this,
    );*/
  }

  midiOn({ key }: { key: number }) {
    if (this.listening) this.piano!.onPressKey(key);
  }

  midiOff({ key }: { key: number }) {
    if (this.listening) this.piano!.onReleaseKey(key);
  }

  playNote(base: BaseNote, octaveMod: number = 0) {
    const number = getMidiNumberFromBaseNote(base);
    this.piano!.onPressKey(number + (octaveMod + this.octave!) * 12);
  }

  releaseNote(base: BaseNote, octaveMod: number = 0) {
    const number = getMidiNumberFromBaseNote(base);
    this.piano!.onReleaseKey(number + (octaveMod + this.octave!) * 12);
  }

  passObjective(last?: boolean) {
    this.listening = false;
    super.passObjective(last);
  }

  startExercise(): void {
    this.listening = true;
  }

  onExerciseOver() {
    super.onExerciseOver(1500);
  }

  setObjective(): void {
    console.log("updating objective");
    const obj = this.config!.objectives[this.currentObjective!];
    console.debug(obj);
    if (obj.startAfter)
      this.time.delayedCall(
        obj.startAfter * 1000,
        () => (this.listening = true),
        [],
        this,
      );
    this.instruction?.destroy();
    this.instruction = new Instruction(
      this,
      obj.text,
      this.config!.textPromptPosition,
    );
    if (obj.target !== "any") {
      this.piano?.setValidator(
        (note) => note === getMidiNumberFromNote(obj.target as Note),
      );
      this.piano?.highlightKey(obj.target as Note, true);
    }
    this.piano?.addKeyListener(
      "down",
      obj.target,
      () => {
        if (!this.listening) return;
        this.passObjective(
          this.currentObjective === this.config!.objectives.length - 1,
        );
        this.piano?.removeKeyListener("down", obj.target);
      },
      this,
      false,
    );
  }

  transition(out?: boolean, onDone?: () => void, scope?: object): void {
    this.instruction?.hide();
    this.tweens.add({
      targets: this.piano,
      x: this.cameras.main.width * 2,
      duration: 1000,
      onComplete: onDone,
      callbackScope: scope,
    });
  }

  unload() {
    console.log("scene: unloading");
    this.timers.forEach((timer) => timer.remove());
    this.time.removeAllEvents();
    this.piano?.destroy();
    RemoveListener(ReactToExerciseEventType.MidiOff);
    RemoveListener(ReactToExerciseEventType.MidiOn);
    RemoveListener(ReactToExerciseEventType.UpdateObjective);
    super.unload();
  }

  stop() {
    this.scene.stop();
  }

  update() {}

  changeScene() {
    // the piano appears in the first scene so we're just going to start by loading the first exercise
  }
}
