import { colors } from "../config";
import NoteSequenceExercise from "../Scenes/NoteSequenceExercise";
import GraphicObject from "./GraphicObject";
import IPlayable from "./Playable";
import Progress, { ProgressState, Result } from "./Progress";

export interface NoteData {
  length: NoteLength;
}

export type NoteLength = "quarter" | "half" | "whole";

export function getNoteLengthInBeats(length: NoteLength): number {
  switch (length) {
    case "whole":
      return 4;
    case "half":
      return 2;
    case "quarter":
      return 1;
    default:
      throw new Error(
        'note length must be one of [ "whole", "half", "quarter" ]!',
      );
  }
}

export function getNoteLengthInMs(length: NoteLength, tempo: number): number {
  const msPerBeat = (60 / tempo) * 1000;
  return msPerBeat * getNoteLengthInBeats(length);
}

type StemDirection = "up" | "down";

export default class Note extends GraphicObject implements IPlayable {
  isFocused: boolean = false;
  progressIndicatorWidthCap: number = 0;
  noteLength: NoteLength;
  tempo: number;
  depth: number;

  noteGraphic: Phaser.GameObjects.Image;
  noteGraphicMask: Phaser.GameObjects.Image;
  noteStem: Phaser.GameObjects.Rectangle | null = null;
  stemDirection: StemDirection | null;

  tweenHandle: Phaser.Tweens.Tween | null = null;
  shakeHandle: Phaser.Tweens.Tween | null = null;

  timer: Phaser.Time.TimerEvent | null = null;
  noteStartTime: number | null = null;

  progress: Progress | null = null;

  width: number;

  result: Result | null = null;

  // Index of the note in its parent exercise.
  idx: number;

  scale: number = 1;

  x: number;
  y: number;

  resetColorTimer: Phaser.Time.TimerEvent | null = null;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    width: number,
    noteLength: NoteLength,
    tempo: number,
    stemDirection: StemDirection = "up",
    idx: number,
    hidden?: boolean,
  ) {
    super(scene, x, y, "image");
    this.x = x;
    this.y = y;
    this.idx = idx;
    this.stemDirection = stemDirection;
    this.width = width;
    this.depth = 0;
    this.noteLength = noteLength;
    const texture = `${noteLength}_note`;
    this.noteGraphic = scene.add.image(x, y, texture);
    this.noteGraphicMask = scene.add.image(x, y, `${texture}_mask`);
    const adjustedY = y; // - this.noteGraphic.height / 2;
    this.noteGraphic.setY(adjustedY);
    this.noteGraphicMask.setY(adjustedY);
    this.setPosition(x, adjustedY);
    const stemWidth = 0.07 * this.noteGraphic.width;
    if (noteLength !== "whole") {
      let stemYOffset = y - this.noteGraphic.height * 2.1;
      if (stemDirection === "down") {
        this.noteGraphic.setScale(1, -1);
        this.noteGraphicMask.setScale(1, -1);
        stemYOffset = this.y + this.noteGraphic.height * 1.1;
      }
      this.noteStem = scene.add.rectangle(
        this.x + this.noteGraphic.width / 2 - stemWidth / 2,
        stemYOffset,
        stemWidth,
        this.noteGraphic.height * 3,
      );
      this.noteStem.setDepth(this.depth - 2);
      this.noteStem.setFillStyle(colors.fg, 1);
    }
    this.noteGraphicMask.setDepth(this.depth - 1);
    this.noteGraphicMask.setTintFill(colors.bg);
    this.noteGraphic.setTintFill(colors.fg);
    this.tempo = tempo;
    if (hidden) {
      this.noteGraphic.setAlpha(0);
      this.noteGraphicMask.setAlpha(0);
      this.noteStem?.setAlpha(0);
    }
  }

  /**
   * @method setDepth
   * @description Sets the z-index of the note graphic and its 'mask'.
   * The mask is always rendered one layer below the note graphic.
   * @param depth {number} - The z-index of the note graphic and its 'mask'.
   */
  setDepth(depth: number) {
    this.depth = depth;
    this.noteGraphic.setDepth(this.depth);
    this.noteGraphicMask.setDepth(this.depth - 1);
    this.noteStem?.setDepth(this.depth - 2);
  }

  setScale(scale: number): void {
    this.noteGraphic.setScale(scale);
    this.noteGraphicMask.setScale(scale);
    this.noteStem?.setScale(scale);
    this.scale = scale;
    const stemWidth = 0.07 * this.noteGraphic.displayWidth;
    let stemYOffset = this.y - this.noteGraphic.displayHeight * 1.7;
    if (this.stemDirection == "down") {
      stemYOffset = this.y + this.noteGraphic.displayHeight * 0.7;
    }
    this.noteStem?.setPosition(
      this.x + this.noteGraphic.displayWidth / 2 - stemWidth / 2,
      stemYOffset,
    );
    const progressHeight = 60 * window.devicePixelRatio;
    this.progress?.setPosition(
      this.x,
      this.y + this.noteGraphic.displayHeight / 2 - progressHeight,
    );
  }

  draw() {
    // Since were using image objects, we dont need to manually render them to the canvas as they should be
    // managed and drawn by the Scene itself.
    // We may need to set the tint fill color of the mask to match the background color of the scene, in case
    // the scene background color changes.
    // Therefore, this behaves more like an update function than a draw function.
    this.noteGraphicMask.setTintFill(colors.bg);
  }

  destroy() {
    this.isFocused = false;
    this.noteGraphic.destroy();
    this.noteGraphicMask.destroy();
    this.noteStem?.destroy();
  }

  /// When the first note in a sequence is played off time, the note graphic will shake to give a visual indication
  /// that the note was played too early or too late.
  shake(duration: number): void {
    const pos = this.noteGraphic.x;
    const maskPos = this.noteGraphicMask.x;
    const stemPos = this.noteStem?.x;
    const dpr = window.devicePixelRatio;
    this.shakeHandle = this.scene.tweens.add({
      targets: { value: 0 },
      value: Math.PI * 2,
      duration,
      ease: Phaser.Math.Easing.Sine.In,
      repeat: 1,
      onUpdate: (_tween, _target, _key, current) => {
        this.noteGraphic.setX(pos + Math.sin(current) * 10 * dpr);
        this.noteGraphicMask.setX(maskPos + Math.sin(current) * 10 * dpr);
        this.noteStem?.setX(stemPos! + Math.sin(current) * 10 * dpr);
      },
    });
  }

  setProgressIndicatorMaxWidth(widthCap: number) {
    this.progressIndicatorWidthCap = widthCap;
  }

  /**
   * @method setFocus
   * @description Animates the note graphic and its mask to indicate that the note is in focus or otherwise.
   * @param focus {boolean} - Whether the note should be in focus or not.
   */
  setFocus(focus: boolean) {
    this.isFocused = focus;
    //this.shakeHandle?.reset();
    this.shakeHandle?.remove();
    if (focus) {
      this.noteGraphic.setAlpha(1);
      this.noteStem?.setFillStyle(colors.fg);
      this.noteStem?.setAlpha(1);
      this.noteGraphic?.setTintFill(colors.fg);
      // Stop existing tween if it exists to avoid conflicts
      this.tweenHandle && this.tweenHandle.stop();
      this.tweenHandle =
        this.scene.tweens &&
        this.scene.tweens.add({
          targets: [
            this.noteGraphic,
            this.noteGraphicMask,
            ...(this.noteStem ? [this.noteStem] : []),
          ],
          scale: this.scale * 1.15,
          /*scaleX: 1.15,
          scaleY:
            1.15 *
            (this.noteLength != "whole" && this.stemDirection == "down"
              ? -1
              : 1),*/
          duration: 100,
          yoyo: true,
          ease: "Power1",
          onUpdate: () => this.draw(),
          callbackScope: this,
        });
      // Spawn a new progress indicator at the current position and start the progress bar
      const dpr = window.devicePixelRatio;
      const progressHeight = 60 * dpr * this.scale;
      const yOffset = 0 * dpr;
      if (!this.progress) {
        this.progress = new Progress(
          this,
          this.scene,
          this.x,
          this.y +
            this.noteGraphic.displayHeight / 2 -
            (progressHeight + yOffset),
          progressHeight,
          10 * dpr * this.scale,
          4 * dpr * this.scale,
          -5,
          this.onFinishPlaying.bind(this),
        );
      }
      this.progress.start(
        this.width * this.scale,
        this.width - this.progressIndicatorWidthCap,
        this.getDurationMs(),
        // TODO fix this thing:-
        this.onFinishPlaying.bind(this),
      );
    } else {
      //this.setColor(colors.fg);
      this.noteGraphic.setScale(this.scale);
      // Stop existing tween if it exists to avoid conflicts
      this.tweenHandle && this.tweenHandle.stop();
    }
  }

  getResult() {
    return this.progress?.result;
  }

  onFinishPlaying(result: Result) {
    console.debug("finish");
    this.result = result;
    switch (result) {
      case Result.Missed:
        this.noteGraphic.setTintFill(colors.noteFillMiss);
        this.noteStem?.setFillStyle(colors.noteFillMiss);
        break;
      case Result.OffTime:
        this.noteGraphic.setTintFill(colors.noteFillOffTime);
        this.noteStem?.setFillStyle(colors.noteFillOffTime);
        break;
      case Result.Success:
        this.noteGraphic.setTintFill(colors.noteFillOnTime);
        this.noteStem?.setFillStyle(colors.noteFillOnTime);
        break;
    }
    //(this.scene as NoteSequenceExercise).onNoteComplete(result, this.idx);
  }

  reset(forceColor?: boolean) {
    console.debug("resetting with ", forceColor);
    this.progress?.destroy();
    this.progress = null;
    this.setFocus(false);
    if (forceColor) {
      if (this.resetColorTimer) {
        this.resetColorTimer.remove();
        this.resetColorTimer = null;
      }
      this.setColor(colors.fg);
    } else {
      this.resetColorTimer = this.scene.time.delayedCall(
        2500,
        () => this.setColor(colors.fg),
        [],
        this,
      );
    }
  }

  setTimingState(state: ProgressState): void {
    this.resetColorTimer?.remove();
    this.progress?.setProgressState(state);
  }

  /**
   * @method setColor
   * @param color The fill color of the note graphic.
   * @description Sets the fill color of the note graphic object.
   */
  setColor(color: number) {
    this.noteGraphic.setTintFill(color);
    this.noteStem?.setFillStyle(color);
    this.draw();
  }

  holdNote() {
    this.resetColorTimer?.remove();
    //this.progress?.startProgress();
    //this.transitionState(NotePlayState.Idle, TransitionTrigger.KeyDown);
  }

  releaseNote() {
    this.progress?.stopProgress();
    //this.transitionState(NotePlayState.Late, TransitionTrigger.KeyUp);
  }

  getDurationMs(): number {
    return getNoteLengthInMs(this.noteLength, this.tempo);
  }

  getWidth() {
    if (this.noteGraphic) return this.noteGraphic.displayWidth;
    else return 0;
  }

  getHeight() {
    if (this.noteGraphic) return this.noteGraphic.displayHeight;
    else return 0;
  }

  fadeIn(duration: number, delay: number, onDone?: () => void, ctx?: any) {
    if (this.noteStem) this.noteStem.alpha = 0;
    this.noteGraphic!.alpha = 0;
    this.noteGraphicMask!.alpha = 0;
    this.scene.tweens.add({
      targets: [
        this.noteGraphicMask,
        this.noteGraphic,
        ...(this.noteStem ? [this.noteStem] : []),
      ],
      alpha: 1,
      duration,
      delay,
      ease: "Power1",
      onComplete: onDone,
      callbackScope: ctx,
    });
  }

  setAlpha(alpha: number) {
    this.noteStem?.setAlpha(alpha);
    this.noteGraphicMask?.setAlpha(alpha);
    this.noteGraphic?.setAlpha(alpha);
  }

  update(cursorPos: number) {
    this.progress?.update(cursorPos);
  }

  getState() {
    return this.progress?.progressState;
  }

  getPosition() {
    return {
      x: this.x,
      y: this.y,
    };
  }
}
