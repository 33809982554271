import { Events } from "phaser";
import { Timestamp } from "Types/ExerciseData";

export enum ReactToExerciseEventType {
  UpdateObjective = "update-objective",
  PauseExercise = "pause-exercise",
  ResumeExercise = "resume-exercise",
  StartExercise = "start-exercise",
  MidiOn = "midi-on",
  MidiOff = "midi-off",
  SkipExercise = "skip-exercise",
}

type UpdateObjective = [ReactToExerciseEventType.UpdateObjective];
type PauseExercise = [ReactToExerciseEventType.PauseExercise];
type ResumeExercise = [ReactToExerciseEventType.ResumeExercise];
type StartExercise = [ReactToExerciseEventType.StartExercise];
type MidiOn = [ReactToExerciseEventType.MidiOn, { key: number }];
type MidiOff = [ReactToExerciseEventType.MidiOff, { key: number }];
type SkipExercise = [ReactToExerciseEventType.SkipExercise];

export type ReactToExerciseEvent =
  | UpdateObjective
  | PauseExercise
  | ResumeExercise
  | StartExercise
  | MidiOn
  | MidiOff
  | SkipExercise;

export enum ExerciseToReactEventType {
  ObjectiveComplete = "objective-complete",
  ExercisePaused = "exercise-paused",
  ExerciseResumed = "exercise-resumed",
  CurrentSceneReady = "current-scene-ready",
  ShowExercise = "show-exercise",
  ObjectiveSkipped = "objective-skipped",
  HideExercise = "hide-exercise",
  SkipButtonRequest = "skip-button-request",
}

type ObjectiveComplete = [
  ExerciseToReactEventType.ObjectiveComplete,
  { jumpTo: Timestamp },
];
type ExercisePaused = [ExerciseToReactEventType.ExercisePaused];
type ExerciseResumed = [ExerciseToReactEventType.ExerciseResumed];
type CurrentSceneReady = [
  ExerciseToReactEventType.CurrentSceneReady,
  Phaser.Scene,
];
type ShowExercise = [typeof ExerciseToReactEventType.ShowExercise];
type ObjectiveSkipped = [
  ExerciseToReactEventType.ObjectiveSkipped,
  { jumpTo: Timestamp },
];
type HideExercise = [ExerciseToReactEventType.HideExercise];
type SkipButtonRequest = [ExerciseToReactEventType.SkipButtonRequest];

type ExerciseToReactEvent =
  | ObjectiveComplete
  | ExercisePaused
  | ExerciseResumed
  | CurrentSceneReady
  | HideExercise
  | ObjectiveSkipped
  | ShowExercise
  | SkipButtonRequest;

export const UpdateObjective = () =>
  [ReactToExerciseEventType.UpdateObjective] as UpdateObjective;
export const PauseExercise = () =>
  [ReactToExerciseEventType.PauseExercise] as PauseExercise;
export const ResumeExercise = () =>
  [ReactToExerciseEventType.ResumeExercise] as ResumeExercise;
export const StartExercise = () =>
  [ReactToExerciseEventType.StartExercise] as StartExercise;
export const MidiOn = (key: number) =>
  [ReactToExerciseEventType.MidiOn, { key }] as MidiOn;
export const MidiOff = (key: number) =>
  [ReactToExerciseEventType.MidiOff, { key }] as MidiOff;
export const SkipExercise = () =>
  [ReactToExerciseEventType.SkipExercise] as SkipExercise;

export const ListenUpdateObjective = (cb: () => void) =>
  [ReactToExerciseEventType.UpdateObjective, cb] as Cb<UpdateObjective>;
export const ListenPauseExercise = (cb: () => void) =>
  [ReactToExerciseEventType.PauseExercise, cb] as Cb<PauseExercise>;
export const ListenResumeExercise = (cb: () => void) =>
  [ReactToExerciseEventType.ResumeExercise, cb] as Cb<ResumeExercise>;
export const ListenStartExercise = (cb: () => void) =>
  [ReactToExerciseEventType.StartExercise, cb] as Cb<StartExercise>;
export const ListenMidiOn = (cb: (arg: { key: number }) => void) =>
  [ReactToExerciseEventType.MidiOn, cb] as Cb<MidiOn>;
export const ListenMidiOff = (cb: (arg: { key: number }) => void) =>
  [ReactToExerciseEventType.MidiOff, cb] as Cb<MidiOff>;
export const ListenSkipExercise = (cb: () => void) =>
  [ReactToExerciseEventType.SkipExercise, cb] as Cb<SkipExercise>;

export const ObjectiveComplete = (jumpTo: Timestamp) =>
  [ExerciseToReactEventType.ObjectiveComplete, { jumpTo }] as ObjectiveComplete;
export const ExercisePaused = () =>
  [ExerciseToReactEventType.ExercisePaused] as ExercisePaused;
export const ExerciseResumed = () =>
  [ExerciseToReactEventType.ExerciseResumed] as ExerciseResumed;
export const ObjectiveSkipped = (jumpTo: Timestamp) =>
  [ExerciseToReactEventType.ObjectiveSkipped, { jumpTo }] as ObjectiveSkipped;
export const HideExercise = () =>
  [ExerciseToReactEventType.HideExercise] as HideExercise;
export const ShowExercise = () =>
  [ExerciseToReactEventType.ShowExercise] as ShowExercise;
export const SkipButtonRequest = () =>
  [ExerciseToReactEventType.SkipButtonRequest] as SkipButtonRequest;
export const CurrentSceneReady = (scene: Phaser.Scene) =>
  [ExerciseToReactEventType.CurrentSceneReady, scene] as CurrentSceneReady;

export const ListenObjectiveComplete = (
  cb: (arg: { jumpTo: Timestamp }) => void,
) => [ExerciseToReactEventType.ObjectiveComplete, cb] as Cb<ObjectiveComplete>;
export const ListenExercisePaused = (cb: () => void) =>
  [ExerciseToReactEventType.ExercisePaused, cb] as Cb<ExercisePaused>;
export const ListenExerciseResumed = (cb: () => void) =>
  [ExerciseToReactEventType.ExerciseResumed, cb] as Cb<ExerciseResumed>;
export const ListenObjectiveSkipped = (
  cb: (arg: { jumpTo: Timestamp }) => void,
) => [ExerciseToReactEventType.ObjectiveSkipped, cb] as Cb<ObjectiveSkipped>;
export const ListenHideExercise = (cb: () => void) =>
  [ExerciseToReactEventType.HideExercise, cb] as Cb<HideExercise>;
export const ListenShowExercise = (cb: () => void) =>
  [ExerciseToReactEventType.ShowExercise, cb] as Cb<ShowExercise>;
export const ListenSkipButtonRequest = (cb: () => void) =>
  [ExerciseToReactEventType.SkipButtonRequest, cb] as Cb<SkipButtonRequest>;
export const ListenCurrentSceneReady = (cb: () => void) =>
  [ExerciseToReactEventType.CurrentSceneReady, cb] as Cb<CurrentSceneReady>;

// Used to emit events between React components and Phaser scenes
// https://newdocs.phaser.io/docs/3.70.0/Phaser.Events.EventEmitter
export const EventBus = new Events.EventEmitter();

/// Send event from phaser to react
export const NotifyReact = ([type, payload]: ExerciseToReactEvent) => {
  console.debug("notifying react", type, payload);
  EventBus.emit(type, payload);
};
/// Send event from react to phaser
export const NotifyPhaser = ([type, payload]: ReactToExerciseEvent) => {
  console.debug("notifying phaser", type, payload);
  EventBus.emit(type, payload);
};

export type Cb<T extends [any, any?]> = [[T][0][0], (arg: T[1]) => void];

/// Listen for events emitted by react
export function ListenForReactEvent<T extends ReactToExerciseEvent>(
  cb: Cb<T>,
  ctx?: object,
  once?: boolean,
) {
  once ? EventBus.once(cb[0], cb[1], ctx) : EventBus.on(cb[0], cb[1], ctx);
}

/// Listen for events emitted by phaser
export function ListenForPhaserEvent<T extends ExerciseToReactEvent>(
  cb: Cb<T>,
  ctx?: object,
) {
  EventBus.on(cb[0], cb[1], ctx);
}

export const RemoveListener = (
  event: ReactToExerciseEventType | ExerciseToReactEventType,
  fn?: () => void,
) => EventBus.off(event, fn);
