import { Scene } from "phaser";

export class Preloader extends Scene {
  constructor() {
    super("Preloader");
  }

  init() {
    //  We loaded this image in our Boot Scene, so we can display it here
    this.add.image(512, 384, "background");

    //  A simple progress bar. This is the outline of the bar.
    this.add.rectangle(512, 384, 468, 32).setStrokeStyle(1, 0xffffff);
    this.add.text(100, 100, "loading...", { font: "bold 18px Lato" });

    //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
    const bar = this.add.rectangle(512 - 230, 384, 4, 28, 0xffffff);

    //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
    this.load.on("progress", (progress: number) => {
      //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
      bar.width = 4 + 460 * progress;
    });
  }

  preload() {
    this.load.setPath("assets");
    const asyncLoader = (loaderPlugin: any) =>
      new Promise((resolve, reject) => {
        loaderPlugin.on("filecomplete", resolve).on("loaderror", reject);
        loaderPlugin.start();
      });
    let promises: Promise<any>[] = [];
    const loadAsset = (load: any) => {
      promises.push(asyncLoader(load));
    };
    //  Load the assets for the game - Replace with your own assets

    // SVG loading in phaser, by nature, rasterises the SVG into a bitmap texture so it can no longer
    // be scaled like a normal vector graphic. Therefore, while loading the SVG itself, we pass in a scale
    // property that rasterises the svg at a high enough resolution such that we dont need to scale it up
    // any further during gameplay, avoiding blurriness.

    // Because of retina displays rendering canvases at lower resolutions than the device, we need to
    // raster all svgs at a higher resolution to avoid blurriness. We do this by scaling up all of our other
    // scaling factors by the device pixel ratio for consistency across different hardware.
    const dpr = window.devicePixelRatio || 1;
    // Rasterise svgs at a 2x size, in case they need to be scaled up during gameplay. Also account for the pixel
    // ratio as stated above by multiplying the scaling factor by the dpr.
    const scale = dpr * 2;

    // Down here is where we load our assets:-
    const load = async () => {
      loadAsset(this.load.image("white_key", "white_key.png"));
      loadAsset(this.load.image("black_key", "black_key.png"));
      loadAsset(this.load.image("black_key_shadow", "black_key_shadow.png"));
      loadAsset(this.load.image("white_key_overlay", "white_key_overlay.png"));
      loadAsset(this.load.image("black_key_overlay", "black_key_overlay.png"));
      loadAsset(this.load.svg("treble_clef", "treble_clef.svg"));
      loadAsset(this.load.svg("bass_clef", "bass_clef.svg"));
      loadAsset(this.load.svg("brace", "brace.svg"));

      loadAsset(
        this.load.svg("half_note", "half_note.svg", {
          scale: scale * 1.3,
        }),
      );

      loadAsset(
        this.load.svg("half_note_mask", "half_note_mask.svg", {
          scale: scale * 1.3,
        }),
      );
      loadAsset(
        this.load.svg("quarter_note", "quarter_note.svg", {
          scale: scale * 1.3,
        }),
      );
      loadAsset(
        this.load.svg("quarter_note_mask", "quarter_note_mask.svg", {
          scale: scale * 1.3,
        }),
      );
      loadAsset(
        this.load.svg("whole_note", "whole_note.svg", { scale: scale * 1.3 }),
      );
      loadAsset(
        this.load.svg("whole_note_mask", "whole_note_mask.svg", {
          scale: scale * 1.3,
        }),
      );
      // The checkmark and crossmark graphics were exported at a higher resolution but instead of fixing it
      // by re-exporting the files themselves, we fix it by scaling them down during import cause we can :)
      loadAsset(
        this.load.svg("checkmark", "checkmark.svg", { scale: 0.55 * scale }),
      );
      loadAsset(
        this.load.svg("crossmark", "crossmark.svg", { scale: 0.4 * scale }),
      );
      loadAsset(
        this.load.svg("skip_button", "skip_button.svg", { scale: 1 * scale }),
      );
      loadAsset(this.load.svg("skip", "skip.svg", { scale: scale * 0.5 }));
      loadAsset(this.load.svg("retry", "retry.svg", { scale: scale * 0.5 }));
      /*this.load.script(
        "webfont",
        "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js"
      );*/
      loadAsset(this.load.audio("click", "click.wav"));
      loadAsset(this.load.audio("success", "success.wav"));
      return await Promise.all(promises);
    };
    load();
  }

  create() {
    //  When all the assets have loaded, it's often worth creating global objects here that the rest of the game can use.
    //  For example, you can define global animations here, so we can use them in other scenes.
    // We start with the scene with the piano and then use the `changeScene()` method in the Exercise class to switch
    // to the scene containing the first exercise.
    this.scene.start("Empty", {});
  }
}
