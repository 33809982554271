import React from "react";
import { Box } from "@mui/material";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import { IRefPhaserGame, PhaserGame } from "Phaser/PhaserGame";
import {
  ExerciseToReactEventType,
  ListenExercisePaused,
  ListenExerciseResumed,
  ListenForPhaserEvent,
  ListenHideExercise,
  ListenObjectiveComplete,
  ListenObjectiveSkipped,
  ListenShowExercise,
  ListenSkipButtonRequest,
  NotifyPhaser,
  PauseExercise,
  ReactToExerciseEventType,
  RemoveListener,
  ResumeExercise,
  SkipExercise,
} from "Phaser/EventBus";
import { NoteData } from "Phaser/GameObjects/Note";
import { Note } from "Phaser/GameObjects/Piano";
import ChapterList, { ChapterSection, getTime, Timestamp } from "../Chapters";
import "./style.css";
import Button from "@mui/material/Button";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import Fullscreen from "Components/Fullscreen";
import { markChapterAsCompleted } from "Actions/chapter";
import { useDispatch } from "react-redux";
import { setLevelSelect } from "Actions/app";
import { useNavigate } from "react-router-dom";
import Play from "../../../assets/images/Play.png";
import Tutorial, {
  ChapterData,
  ChapterType,
  ExerciseScene,
} from "Types/ExerciseData";
import TutorialData from "Constants/Tutorial";
import PhraseSvgProcessor, {
  PhraseObject,
  PhraseData,
} from "../SvgToPhaserPhrase";

type Visibility = "hidden" | "visible";

const SkipButton = ({
  skip,
  visibility,
}: {
  skip: () => void;
  visibility?: Visibility;
}) => {
  return (
    <Button
      id="skip-button"
      className={visibility}
      sx={{
        backgroundColor: "#4A5AA1",
        borderRadius: "35px",
        width: "5%",
        minWidth: "unset",
        height: "calc(5% * 1.777778)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "absolute",
        bottom: "10%",
        opacity: 0,
        right: "10%",
        padding: "none",
        pointerEvents: "all",
      }}
      variant="contained"
      onClick={() => {
        skip();
      }}
      onKeyUp={(e) => {
        if (e.key === " ") e.preventDefault();
      }}
    >
      <FastForwardRounded sx={{ height: "100%", width: "unset" }} />
    </Button>
  );
};

const ProceedButton = ({
  proceed,
  visibility,
}: {
  proceed: () => void;
  visibility: Visibility;
}) => {
  return (
    <Box onClick={proceed} id="proceed-button">
      <Box sx={playButtonStyles} className={visibility}>
        <img
          alt="play_button"
          style={{ maxWidth: "100%", maxHeight: "100%", margin: "0px" }}
          src={Play}
        />
      </Box>
    </Box>
  );
};

const playButtonStyles = {
  position: "absolute",
  width: "70px",
  height: "70px",
  top: "calc(58%)",
  left: "calc(50% - 35px)",
  padding: "0px",
  margin: "0px",
  borderRadius: "50%",
  pointerEvents: "all",
  "&:hover": {
    background: "rgba(212,134,108,1.0)",
    cursor: "pointer",
    transition: "0.3s",
    boxShadow: "0px 0px 5px 5px rgb(212,134,108,.5)",
  },
};

// These are the layouts that will be rendered!!!
const InteractiveTemplate1 = ({ tutorialData }: { tutorialData: Tutorial }) => {
  const [lastPauseEventTime, setLastPauseEventTime] = React.useState<
    number | null
  >(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [narrationShouldPause, setNarrationShouldPause] =
    React.useState<boolean>(false);

  //const [activeExercise, setActiveExercise] = React.useState(0);
  //const [, setCompletionStatus] = React.useState<number[]>([]);
  //const [exerciseEnd, setExerciseEnd] = React.useState<number | null>();
  const [currentChapter, setCurrentChapter] = React.useState<number>(0);
  const [completedObjectiveCount, setCompletedObjectiveCount] =
    React.useState(0);
  //const [loaded, setLoaded] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phrasesToBeProcessed, setPhrasesToBeProcessed] = React.useState<{
    phrases: PhraseData[];
    callback: (phrases: Map<string, PhraseObject>) => void;
  } | null>(null);

  /*const [tutorialData] = React.useState<Tutorial>({
  });*/
  //const [tutorialData] = React.useState<Tutorial>(TutorialData[2]);
  const [chapterData, setChapterData] = React.useState<ChapterData[]>();

  React.useEffect(() => {
    setChapterData(tutorialData?.sections.flatMap((x) => x.chapters));
  }, [tutorialData]);

  const phaserRef = React.useRef<IRefPhaserGame | null>(null);
  const videoRef = React.useRef<ReactPlayer>(null);

  const [exercisePaused, setExercisePaused] = React.useState(true);
  const [showExercise, setShowExercise] = React.useState(false);
  const [skipButtonVisibility, setSkipButtonVisibility] = React.useState<
    Visibility | undefined
  >(undefined);
  const [proceedButtonVisibility, setProceedButtonVisibility] =
    React.useState<Visibility>("hidden");

  /*React.useEffect(() => {
    console.log(interactiveConfig.toJS());
  }, [interactiveConfig]);*/

  const loadScene = React.useCallback(
    (name: string, props: any) => {
      if (phaserRef.current?.scene) {
        phaserRef.current.scene.scene.start(name, {
          ...props,
        });
        console.debug("scene: ", phaserRef.current);
      } else {
        console.error("phaser scene manager not initialised!");
      }
    },
    [phaserRef],
  );

  //const onSeek = React.useCallback(() => setLastPauseEventTime(0), []);
  const onSeek = () => {};

  const CheckChapter = React.useCallback(
    (chapter: number) => {
      console.log("scene: new scene");
      phaserRef.current?.scene?.scene.stop();
      if (!chapterData || !tutorialData) return;
      const [type, data] = chapterData[chapter || 0]?.data;
      switch (type) {
        case ChapterType.Lecture: {
          // Mark a video chapter as complete automatically after two seconds watching
          const t = setTimeout(
            () =>
              dispatch(
                markChapterAsCompleted({
                  lesson: tutorialData.level,
                  chapter: chapter || 0,
                }),
              ),
            2000,
          );
          return () => clearTimeout(t);
        }
        case ChapterType.Exercise: {
          console.log("loading scene");
          const [name, config] = data.scene;
          if (name === ExerciseScene.StaffExercise) {
            setPhrasesToBeProcessed({
              phrases: config.objectives.map(
                ({ svgUrl, phraseId, jsonData }) => ({
                  url: svgUrl,
                  id: phraseId,
                  musicData: jsonData,
                }),
              ),
              callback: (phrases) => {
                const updatedConfig = {
                  ...config,
                  objectives: config.objectives.map((obj) => ({
                    ...obj,
                    notes: phrases.get(obj.phraseId)!.notes,
                    staff: phrases.get(obj.phraseId)!.grandStaff,
                  })),
                };
                loadScene(name, updatedConfig);
              },
            });
          } else {
            loadScene(name, config);
          }
          break;
        }
      }
    },
    [tutorialData, chapterData, dispatch, loadScene],
  );

  // Side effect triggered on focused chapter change
  React.useEffect(() => {
    console.log("scene: chapter change");
    CheckChapter(currentChapter);
    /*if (!chapterData || !tutorialData) return;
    phaserRef.current?.scene?.scene.stop();
    const [type, data] = chapterData[currentChapter || 0]?.data;
    switch (type) {
      case ChapterType.Lecture: {
        // Mark a video chapter as complete automatically after two seconds watching
        const t = setTimeout(
          () =>
            dispatch(
              markChapterAsCompleted({
                lesson: tutorialData.level,
                chapter: currentChapter || 0,
              }),
            ),
          2000,
        );
        return () => clearTimeout(t);
      }
      case ChapterType.Exercise: {
        const [name, config] = data.scene;
        if (name === ExerciseScene.StaffExercise) {
          setPhrasesToBeProcessed({
            phrases: config.objectives.map(
              ({ svgUrl, phraseId, jsonData }) => ({
                url: svgUrl,
                id: phraseId,
                musicData: jsonData,
              }),
            ),
            callback: (phrases) => {
              const updatedConfig = {
                ...config,
                objectives: config.objectives.map((obj) => ({
                  ...obj,
                  notes: phrases.get(obj.phraseId)!.notes,
                  staff: phrases.get(obj.phraseId)!.grandStaff,
                })),
              };
              loadScene(name, updatedConfig);
            },
          });
        } else {
          loadScene(name, config);
        }
        break;
      }
      default:
        throw new TypeError("Unknown chapter type: " + type);
    }*/
  }, [currentChapter, CheckChapter]);

  const onProgress = React.useCallback(
    (progress: OnProgressProps) => {
      if (!chapterData) return;
      const current = progress.playedSeconds;
      const max = chapterData
        .filter((x) => getTime(x.start) <= current)
        .reduce(
          (max, chapter, idx, arr) =>
            chapter.start > arr[max].start ? idx : max,
          0,
        );
      setCurrentChapter(max);
      const [type, data] = chapterData[max || 0]?.data;
      let eventThisFrame = false;
      if (type === ChapterType.Exercise) {
        console.log("haha");
        const ev = data.events.find((e) => {
          const diff = current - getTime(e.at);
          return diff <= 1.0 && diff >= 0;
        });
        if (ev && getTime(ev.at) - current <= 0.5)
          console.log(current, getTime(ev.at));
        if (ev) {
          eventThisFrame = true;
          if (lastPauseEventTime === getTime(ev?.at)) return;
          if (ev.pause) {
            console.log("pausing", current, ev.at);
            setNarrationShouldPause(true);
            setLastPauseEventTime(getTime(ev.at));
            setIsPlaying(false);
          }
          if (ev.phaserEvent) NotifyPhaser(ev.phaserEvent);
        }
      }
      if (!eventThisFrame && current >= (lastPauseEventTime ?? 0) + 1.5)
        setLastPauseEventTime(null);
    },
    [lastPauseEventTime, chapterData],
  );

  /*const onProgress = React.useCallback(
    (progress: OnProgressProps) => {
      const { playedSeconds } = progress;
      // was there an event this frame?
      let eventThisFrame = false;
      const max = sections
        .flatMap((x) => x.chapters)
        .filter((x) => getTime(x.start) <= playedSeconds)
        .reduce(
          (max, chapter, idx, arr) =>
            chapter.start > arr[max].start ? idx : max,
          0
        );
      setActiveExercise(max);
      if (currentChapter !== max) {
        setCurrentChapter(max);
      }
      for (let indx = 0; indx < interactiveConfig.length; indx++) {
        const config = interactiveConfig[indx];
        const secs = getTime(config.playedSeconds);
        if (
          config &&
          playedSeconds > secs &&
          playedSeconds < secs + 1 &&
          // This is still not rock solid as jumping back immediately to the tutorial you just completed
          // wont pause the video due to the lastPauseEventTime 'guard' thing so we need to unset that
          // state variable whenever the video is seeked (sought?! english is confusing). The same applies
          // for moving between 'chapter's in the video. Basically, when the user manipulates the position
          // of the playback head manually, you reset the lastPauseEventTime guard.
          secs !== lastPauseEventTime
        ) {
          eventThisFrame = true;
          if (config.videoEnd) setProceedButtonVisibility("visible");
          if (config.pause) {
            setNarrationShouldPause(true);
            setIsPlaying(false);
          }
          setLastPauseEventTime(secs);
          //setInteractiveComponentVisibleAtIndex(indx, true);
          //setExerciseActive(true);
          if (config.event) {
            if (config.end) setExerciseEnd(getTime(config.end));
            switch (config.event[0]) {
              case "SceneEvent":
                const { event, args } = config.event[1];
                console.debug("emitting event, ", event, args);
                if (args) EventBus.emit(event, ...args);
                else EventBus.emit(event);
                break;
              case "SceneLoad":
                setExercisePaused(false);
                const scene = config.event[1][0];
                if (
                  scene === "NoteSequenceExercise" ||
                  scene === "ExerciseTempoPractice"
                ) {
                  setSkipDestination(getTime(config.event[1][1].skipTo));
                }
                loadScene(config.event[1][0], {
                  ...config.event[1][1],
                });
                break;
              default:
                throw new Error("Unexpected event!");
            }
          }
        }
      }
      if (!eventThisFrame && playedSeconds > 1.5 + lastPauseEventTime) {
        setLastPauseEventTime(-1);
      }
    },
    [interactiveConfig, loadScene, sections, lastPauseEventTime, currentChapter]
  );*/

  const playVideo = React.useCallback(() => {
    setIsPlaying(true);
    setNarrationShouldPause(false);
    //setInteractiveComponentHidden(true);
    //setExerciseActive();
  }, [setIsPlaying]);

  const resume = React.useCallback(() => {
    setIsPlaying(!narrationShouldPause);
    NotifyPhaser(ResumeExercise());
  }, [narrationShouldPause]);

  const pause = React.useCallback(() => {
    setIsPlaying(false);
    NotifyPhaser(PauseExercise());
  }, []);

  React.useEffect(() => {
    // NOTE: This is entirely dependant on the video ALWAYS resuming whenever an exercise gets unloaded
    // due to whatever reason may be (the exercise being completed succesfully / skipped or jumping
    // to a completely different chapter). Point is: if the video playback state doesn't change, this
    // breaks.
    if (isPlaying) {
      setNarrationShouldPause(false);
      setSkipButtonVisibility((prev) => {
        if (prev !== undefined) return "hidden";
        else return undefined;
      });
    }
  }, [isPlaying]);

  const onVideoClick = React.useCallback(
    (e: any) => {
      if (e.target.tagName === "VIDEO") {
        pause();
      }
    },
    [pause],
  );

  const jumpTo = React.useCallback(
    (seconds: number, chapter?: number) => {
      console.debug("scene: jumpting", chapter, phaserRef.current);
      setProceedButtonVisibility("hidden");
      if (videoRef && videoRef.current) videoRef.current.seekTo(seconds);
      setCompletedObjectiveCount(0);
      setIsPlaying(true);
      setShowExercise(false);
      //if (type === "exercise") {
      // Instead of calling this particular function manually, use another function (or effects)
      // to handle all cases of interactive sections becoming inactive / disabled.
      setExercisePaused(true);
      setLastPauseEventTime(null);
      //phaserRef.current?.scene?.scene.stop();
      if (chapter) {
        if (chapter === currentChapter) {
          CheckChapter(chapter);
        } else {
          setCurrentChapter(chapter);
        }
      }
      //NotifyPhaser();
      //}
    },
    [CheckChapter, currentChapter],
  );

  // Spacebar event listener
  React.useEffect(() => {
    console.debug(
      "recomputing keydown",
      isPlaying,
      narrationShouldPause,
      exercisePaused,
    );
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === " ") {
        if (exercisePaused) NotifyPhaser(ResumeExercise());
        else NotifyPhaser(PauseExercise());
        if (isPlaying) setIsPlaying(false);
        else setIsPlaying(!narrationShouldPause);
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [isPlaying, narrationShouldPause, exercisePaused]);

  // Mark an exercise as complete when all of its objectives have been completed
  React.useEffect(() => {
    if (!chapterData) return;
    console.log("objective complete", completedObjectiveCount);
    const ex = chapterData.find((x) => x.id === currentChapter);
    console.debug("ex: ", ex);
    if (ex && ex.data[0] === ChapterType.Exercise) {
      const req = ex.data[1].scene[1].objectives.length ?? 1;
      if (completedObjectiveCount === req) {
        //setCompletionStatus((comp) => [...comp, activeExercise]);
        dispatch(
          markChapterAsCompleted({ lesson: 0, chapter: currentChapter }),
        );
      }
    }
  }, [completedObjectiveCount, chapterData, currentChapter, dispatch]);

  /*
  // Event listener for skipping exercises
  React.useEffect(() => {
    ListenForPhaserEvent("exercise-skipped", () => {
      jumpTo(skipDestination);
    });
    return () => {
      RemoveListener("exercise-skipped");
    };
  }, [jumpTo, skipDestination]);
  */

  const proceed = React.useCallback(() => {
    if (tutorialData.level === 0) {
      dispatch(setLevelSelect({ levelSelect: 1 }));
      navigate("/tutorial");
    } else {
      navigate("/lesson");
    }
  }, [dispatch, navigate]);

  // Listener for "ObjectiveComplete" events
  React.useEffect(() => {
    ListenForPhaserEvent(
      ListenObjectiveComplete(({ jumpTo }: { jumpTo: Timestamp }) => {
        setCompletedObjectiveCount((x) => x + 1);
        //setCompletionStatus((comp) => [...comp, activeExercise]);
        videoRef.current?.seekTo(getTime(jumpTo));
        playVideo();
      }),
    );
    ListenForPhaserEvent(
      ListenObjectiveSkipped(({ jumpTo }: { jumpTo: Timestamp }) => {
        videoRef.current?.seekTo(getTime(jumpTo));
        playVideo();
      }),
    );
    return () => {
      RemoveListener(ExerciseToReactEventType.ObjectiveComplete);
      RemoveListener(ExerciseToReactEventType.ObjectiveSkipped);
    };
  }, [playVideo]);

  // Other phaser event listeners
  React.useEffect(() => {
    ListenForPhaserEvent(ListenShowExercise(() => setShowExercise(true)));
    //ListenForPhaserEvent('exercise-loaded', () => setShowExercise(true));
    ListenForPhaserEvent(ListenExercisePaused(() => setExercisePaused(true)));
    //ListenForPhaserEvent('exercise-paused', () => setExercisePaused(true));
    ListenForPhaserEvent(
      ListenHideExercise(() => {
        setExercisePaused(true);
        setShowExercise(false);
      }),
    );
    /*ListenForPhaserEvent('exercise-unloaded', () => {
      setExercisePaused(true);
      setShowExercise(false);
    });*/
    ListenForPhaserEvent(ListenExerciseResumed(() => setExercisePaused(false)));
    //ListenForPhaserEvent('exercise-resumed', () => setExercisePaused(false));
    ListenForPhaserEvent(
      ListenSkipButtonRequest(() => setSkipButtonVisibility("visible")),
    );
    /*ListenForPhaserEvent('skip-button-request', () =>
      setSkipButtonVisibility('visible')
    );*/
    return () => {
      RemoveListener(ExerciseToReactEventType.HideExercise);
      RemoveListener(ExerciseToReactEventType.ExercisePaused);
      RemoveListener(ExerciseToReactEventType.ExerciseResumed);
      RemoveListener(ExerciseToReactEventType.ShowExercise);
      RemoveListener(ExerciseToReactEventType.SkipButtonRequest);
    };
  }, []);

  // Event listener for pausing playback when tab / window loses focus
  React.useEffect(() => {
    window.addEventListener("blur", () => {
      pause();
    });
  }, [pause]);

  const aspectRatio = 1.777778;
  // Keeps track of the <video> elements width and height which is used later for scaling the canvas
  // to fit the size of the video
  const [videoPlayerSize, setVideoPlayerSize] = React.useState({
    width: 0,
    height: 0,
  });
  const [videoLoaded, setVideoLoaded] = React.useState(false);

  const onResize = React.useCallback(
    (ratio: number) => {
      const vid: HTMLVideoElement = document.getElementsByTagName("video")[0];
      if (!vid) return;
      const height = vid.clientHeight;
      const width = vid.clientWidth;
      const desiredWidth = height * ratio;
      const desiredHeight = width / ratio;
      if (desiredWidth > width) {
        setVideoPlayerSize({ width: width, height: desiredHeight });
        console.debug(width, height, desiredWidth, desiredHeight);
      } else {
        setVideoPlayerSize({ width: desiredWidth, height: height });
        console.debug(width, height, desiredWidth, desiredHeight);
      }
    },
    [setVideoPlayerSize],
  );

  const onVideoLoad = React.useCallback(() => {
    const vid: HTMLVideoElement = document.getElementsByTagName("video")[0];
    const ratio = vid.videoWidth / vid.videoHeight;
    //setAspectRatio(ratio);
    //setVideoDimens({ width: vid.videoWidth, height: vid.videoHeight });
    onResize(ratio);
    setVideoLoaded(true);
  }, [onResize]);

  // Window resize event listener
  React.useEffect(() => {
    window.addEventListener("resize", () => onResize(aspectRatio));
    return () => {
      window.removeEventListener("resize", () => onResize(aspectRatio));
    };
  }, [aspectRatio, onResize]);

  // Check if an element is within the viewport without any clipping
  const isVisible = (el: Element) => {
    const rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  };

  // Scroll to current chapter
  React.useEffect(() => {
    const el = document.querySelector(`#chapter-${currentChapter}`);
    if (el) console.debug(el, isVisible(el));
    if (el && !isVisible(el)) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentChapter]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        background: "linear-gradient(270deg, #1D2F44 28.99%, #071423 90.95%)",
        alignItems: "top",
        justifyContent: "center",
      }}
    >
      <ChapterList
        sections={tutorialData.sections}
        jumpTo={jumpTo}
        active={currentChapter}
        lesson={0}
      />
      <Box
        onClick={onVideoClick}
        sx={{
          display: "flex",
          margin: "20px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          cursor: "pointer",
        }}
      >
        <>
          {phrasesToBeProcessed && (
            <PhraseSvgProcessor
              phrases={phrasesToBeProcessed.phrases}
              callback={(stuff) => {
                setPhrasesToBeProcessed(null);
                phrasesToBeProcessed.callback(stuff);
              }}
            />
          )}
          <Box
            id="svg-processor-parent"
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <ReactPlayer
              // in order to re-pause whenever the times above are reached, the state has to stay in sync
              // with the component
              ref={videoRef}
              onPlay={playVideo}
              //controls={true}
              onSeek={onSeek}
              playing={isPlaying}
              type="video/mp4"
              //url="https://assets.museflow.ai/videos/unit/1/level-0.mp4"
              url={tutorialData.videoUrl}
              onReady={onVideoLoad}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
              onProgress={onProgress}
              width="100%"
              height="100%"
              className="react-player"
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                pointerEvents: "none",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {
                <Box
                  sx={{
                    visibility: videoLoaded ? "visible" : "hidden",
                    width:
                      videoPlayerSize.width > 0 ? videoPlayerSize.width : 1280,
                    height:
                      videoPlayerSize.height > 0 ? videoPlayerSize.height : 720,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <ProceedButton
                    proceed={proceed}
                    visibility={proceedButtonVisibility}
                  />
                  <SkipButton
                    skip={() => NotifyPhaser(SkipExercise())}
                    visibility={skipButtonVisibility}
                  />
                  <Box
                    sx={{
                      zIndex: 99,
                      pointerEvents: "all",
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                    }}
                  >
                    <Fullscreen
                      show_label={false}
                      use_dark_icon={isPlaying || !exercisePaused}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      visibility: showExercise ? "visible" : "hidden",
                    }}
                  >
                    <PhaserGame ref={phaserRef} width={1920} height={1080} />
                  </Box>
                  {!isPlaying && exercisePaused && (
                    <Box
                      onClick={resume}
                      sx={{
                        background: "#000000aa",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: 0,
                        pointerEvents: "all",
                      }}
                    >
                      <p style={{ fontSize: 28 }}>
                        click here or press spacebar to play/pause
                      </p>
                    </Box>
                  )}
                </Box>
              }
            </Box>
          </Box>
        </>
      </Box>
    </Box>
  );
};

export default InteractiveTemplate1;
