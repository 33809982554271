import Phaser from "phaser";
import {
  EventBus,
  ListenForReactEvent,
  ListenUpdateObjective,
  NotifyReact,
  ReactToExerciseEventType,
  RemoveListener,
  ShowExercise,
} from "../EventBus";
import RoundedRect from "../GameObjects/RoundedRect";
import {
  BaseNote,
  Note,
  getBaseNote,
  getMidiNumberFromNote,
  getOctave,
} from "../GameObjects/Piano";
import { colors } from "Phaser/config";
import ExerciseBase, { IConfig, IObjective } from "./ExerciseBase";
import { Dissolve } from "Phaser/Rendering/Transitions";

type Length = "quarter" | "half" | "whole";

type NoteGraphic = [Note, Length];

interface Objective extends IObjective {
  bassNotes: NoteGraphic[];
  trebleNotes: NoteGraphic[];
}

export interface Config extends IConfig<Objective> {
  line_gap_vertical?: number;
  padding_x?: number;
  centre_align_horizontal?: boolean;
  line_thickness?: number;
  width?: number;
  clef_gap?: number;
  clef_padding?: number;
  first_note_offset?: number;
}

function DefaultConfig(config: Config): Config {
  const dpr = window.devicePixelRatio;
  const line_gap_vertical = (config.line_gap_vertical ?? 20) * dpr;
  const padding_x = (config.padding_x ?? 50) * dpr;
  const line_thickness = (config.line_thickness ?? 2) * dpr;
  const width = (config.width ?? 1000) * dpr;
  const clef_gap = (config.clef_gap ?? 60) * dpr;
  const clef_padding = (config.clef_padding ?? 10) * dpr;
  const first_note_offset = (config.first_note_offset ?? 50) * dpr;
  return {
    objectives: config.objectives ?? [],
    firstObjectiveAppearDelay: config.firstObjectiveAppearDelay ?? 0,
    line_gap_vertical,
    padding_x,
    line_thickness,
    width,
    clef_gap,
    clef_padding,
    first_note_offset,
    centre_align_horizontal: config.centre_align_horizontal,
  };
}

function getLineNumber(note: BaseNote) {
  switch (note) {
    case "C":
      return 6;
    case "D":
      return 5;
    case "E":
      return 4;
    case "F":
      return 3;
    case "G":
      return 2;
    case "A":
      return 1;
    case "B":
      return 0;
  }
}

/*function getLineNumberOctave(note: Note, octave: number) {
  return getLineNumber(note) + octave * 7;
}*/

type CursorItem = {
  x: number;
  id: number;
};

interface Database {
  getNote(id: number): NoteObject | null;
}

class NoTimeCursor extends RoundedRect {
  cursorData: CursorItem[];
  endX: number;
  currentNote: number = 0;
  onFinish: () => void;
  cbCtx?: object;
  db?: Database;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    width: number,
    height: number,
    cursorData: CursorItem[],
    endX: number,
    db: Database,
    onFinish: () => void,
    cbCtx?: object,
  ) {
    const color = colors.cursor;
    const radius = width / 2;
    super(
      scene,
      x - width / 2,
      y + height / 2,
      width,
      height,
      radius,
      color,
      0,
    );
    this.cursorData = cursorData;
    this.currentNote = 0;
    this.onFinish = onFinish;
    this.cbCtx = cbCtx;
    this.db = db;
    this.endX = endX;
  }

  next(note: number) {
    if (this.currentNote < 0) return;
    const speed = 2.5;
    const { id } = this.cursorData[this.currentNote];
    const current = this.db?.getNote(id);
    if (!current) return;
    if (note !== current.midiNote) {
      current.makeWrong();
      return;
    } else {
      current.makeCorrect();
    }
    let targetPos = this.endX;
    if (this.currentNote === this.cursorData.length - 1) {
      this.currentNote = 0;
      this.scene.tweens.add({
        targets: this,
        alpha: 0,
        delay: 1000 / speed,
        ease: "Linear",
        duration: 1000,
        onUpdate: this.draw,
        onDone: () => {
          this.destroy();
        },
        callbackScope: this,
      });
      this.onFinish.call(this.cbCtx);
    } else {
      const next = this.currentNote + 1;
      this.currentNote = next;
      targetPos = this.cursorData[next].x - this.width / 2;
    }
    this.scene.tweens.add({
      targets: this,
      x: targetPos,
      duration: 1000 / speed,
      ease: "Power2",
      onUpdate: this.draw,
      callbackScope: this,
    });
  }

  show() {
    this.scene.tweens.add({
      targets: this,
      alpha: colors.cursorAlpha,
      duration: 1000,
      ease: "Power2",
      onUpdate: this.draw,
      callbackScope: this,
    });
  }

  destroy() {
    this.cursorData.clear();
    this.endX = 0;
    this.currentNote = -1;
    this.onFinish = () => {};
    this.cbCtx = undefined;
    this.db = undefined;
    super.destroy();
  }
}

class NoteObject extends Phaser.GameObjects.Container {
  scene: Phaser.Scene;
  graphic: Phaser.GameObjects.Image;
  stem?: Phaser.GameObjects.Rectangle;
  ledgerLine?: Phaser.GameObjects.Rectangle;
  midiNote: number;
  tweenHandle?: Phaser.Tweens.Tween | null;

  constructor(
    scene: Phaser.Scene,
    note: Note,
    length: Length,
    octave: number,
    x: number,
    config: Config,
  ) {
    super(scene);
    this.scene = scene;
    this.scene.add.existing(this);
    this.midiNote = getMidiNumberFromNote(note);
    const { line_gap_vertical } = config;
    // const bassNotes: Phaser.GameObjects.Group[] = [];
    this.graphic = this.scene.add.image(x, 20, "quarter_note");
    const noteScale = this.graphic.displayHeight / line_gap_vertical!;
    this.graphic.setScale(1 / noteScale);
    const lineNum = getLineNumber(getBaseNote(note))! + (4 - octave) * 7;
    const stemDirection = lineNum <= 0 ? "down" : "up";
    const offset = 4;
    const pos = (line_gap_vertical! / 2) * (lineNum + offset);
    this.graphic.y = pos;
    const stemWidth = 0.12 * this.graphic.displayWidth;
    if (length !== "whole") {
      let stemYOffset = this.graphic.y - this.graphic.displayHeight * 1.7;
      let stemX =
        this.graphic.x + this.graphic.displayWidth / 2 - stemWidth / 2;
      if (stemDirection === "down") {
        stemYOffset = this.graphic.y + this.graphic.displayHeight * 1.7;
        stemX -= this.graphic.displayWidth - stemWidth;
      }
      this.stem = this.scene.add.rectangle(
        //graphic.x + graphic.displayWidth / 2 - stemWidth / 2,
        stemX,
        stemYOffset,
        stemWidth,
        this.graphic.displayHeight * 3,
        0,
      );
      if (lineNum > 5 || lineNum < -5) {
        this.ledgerLine = this.scene.add.rectangle(
          this.graphic.x,
          this.graphic.y,
          this.graphic.displayWidth * 1.5,
          config.line_thickness,
          0,
        );
        //lineThrough.setOrigin(0, 0);
      }
    }
    if (this.ledgerLine) this.add(this.ledgerLine);
    if (this.stem) this.add(this.stem);
    this.add(this.graphic);
  }

  getX() {
    return this.graphic.x;
  }

  setColor(color: number) {
    this.graphic.setTintFill(color);
    if (this.stem) this.stem.fillColor = color;
  }

  makeWrong() {
    const pos = this.graphic.x;
    const stemPos = this.stem?.x;
    //const duration = Math.min((60 / tempo) * 1000, 200);
    const duration = 200;
    this.tweenHandle = this.scene.tweens.add({
      targets: { value: 0 },
      value: Math.PI * 2,
      duration,
      ease: Phaser.Math.Easing.Sine.In,
      repeat: 1,
      onUpdate: (_tween, _target, _key, current) => {
        console.log(current);
        this.graphic.setX(
          pos + Math.sin(current) * 10 * window.devicePixelRatio,
        );
        if (stemPos)
          this.stem?.setX(
            stemPos + Math.sin(current) * 10 * window.devicePixelRatio,
          );
      },
    });
    //this.setColor(colors.noteFillMiss);
  }
  makeCorrect() {
    this.tweenHandle?.remove();
    this.setColor(colors.noteFillOnTime);
  }
  destroy() {
    this.tweenHandle?.remove();
    super.destroy();
  }
}

export default class NoTimeExercise
  extends ExerciseBase<Objective, Config>
  implements Database
{
  config?: Config;
  bassLines: Phaser.GameObjects.Rectangle[] = [];
  trebleLines: Phaser.GameObjects.Rectangle[] = [];
  listening: boolean = true;
  cursor?: NoTimeCursor;
  trebleNotes: NoteObject[] = [];
  appearanceDelay?: Phaser.Time.TimerEvent;

  constructor() {
    super("NoTimeExercise");
  }

  /*createNote(
    note: Note,
    length: Length,
    octave: number,
    x: number
  ): Phaser.GameObjects.Container {
  }*/

  stop() {
    this.scene.stop();
  }

  getNote(id: number): NoteObject | null {
    return this.trebleNotes[id];
  }

  updateObjective() {
    this.currentObjective =
      this.currentObjective !== undefined ? this.currentObjective + 1 : 0;
    super.showInstruction(this.config!.objectives[this.currentObjective].text);
    if (this.currentObjective > 0) {
      this.createGrandStaff(
        this.config!.objectives[this.currentObjective].trebleNotes,
      );
    }
  }

  unload() {
    RemoveListener(ReactToExerciseEventType.UpdateObjective);
    this.unloadGraphics();
    super.unload();
  }

  unloadGraphics() {
    this.cursor?.destroy();
    this.appearanceDelay?.remove();
  }

  startExercise() {
    this.cursor?.show();
  }

  createClef(clef: "bass" | "treble", parent: Phaser.GameObjects.Container) {
    const { width, line_gap_vertical, line_thickness, clef_padding, clef_gap } =
      this.config!;
    const padding_x = 0;
    const clef_lines_height = line_gap_vertical! * 5;
    const lines_y_start = clef === "bass" ? clef_lines_height + clef_gap! : 0;
    const lines: Phaser.GameObjects.Rectangle[] = [];
    for (let i = 0; i < 5; i++) {
      lines.push(
        this.add.rectangle(
          padding_x, // + width / 2,
          lines_y_start + i * line_gap_vertical!,
          0,
          line_thickness,
          0,
          1,
        ),
      );
    }
    lines.forEach((x) => {
      parent.add(x);
      x.alpha = 0;
    });
    lines.forEach((x, idx) =>
      this.tweens.add({
        targets: x,
        alpha: 1,
        width,
        duration: 1000,
        ease: "Power1",
        delay: idx * 100,
      }),
    );
    if (clef === "bass") this.bassLines = lines;
    else this.trebleLines = lines;
    const offset = clef === "bass" ? 1 : 3;
    const clefObj = this.add.image(
      padding_x + clef_padding!,
      lines_y_start + line_gap_vertical! * offset,
      `${clef}_clef`,
    );
    clefObj.setOrigin(0, 0.5);
    const clef_gap_ratio = 1 / 30 / window.devicePixelRatio;
    const scale = clef_gap_ratio * line_gap_vertical!;
    clefObj.setScale(scale);
    parent.add(clefObj);
    clefObj.alpha = 0;
    return clefObj;
  }

  createGrandStaff(trebleNotes: NoteGraphic[], bassNotes: NoteGraphic[] = []) {
    const grandStaff = this.add.container(0, 0);
    const trebleClef = this.createClef("treble", grandStaff);
    const bassClef = this.createClef("bass", grandStaff);
    const staffLinesTotalHeight =
      this.config!.line_gap_vertical! * 9 + this.config!.clef_gap!;
    const brace = this.add.image(
      -5 * window.devicePixelRatio,
      staffLinesTotalHeight / 2,
      "brace",
    );
    const braceScale = brace.displayHeight / staffLinesTotalHeight;
    brace.setScale(1.02 / braceScale);
    brace.setOrigin(1, 0.5);
    brace.alpha = 0;
    grandStaff.add(brace);
    grandStaff.setX(this.config!.padding_x!); // + brace.displayWidth);
    grandStaff.setY(this.cameras.main.height / 2 - staffLinesTotalHeight / 2);
    [trebleClef, bassClef].forEach((x, idx) =>
      this.tweens.add({
        targets: x,
        alpha: 1,
        duration: 1000,
        delay: 500 + 200 * idx,
        ease: "Linear",
      }),
    );
    const braceX = brace.x;
    brace.x -= 50;
    this.tweens.add({
      targets: brace,
      alpha: 1,
      x: braceX,
      duration: 500,
      delay: 600,
      ease: "Power1",
    });
    const leftLine = this.add.rectangle(
      0,
      0 + staffLinesTotalHeight / 2,
      this.config!.line_thickness,
      staffLinesTotalHeight,
      0,
    );
    grandStaff.add(leftLine);
    const block_width = this.config!.line_thickness! * 5;
    const rightBlock = this.add.rectangle(
      this.config!.width! - block_width / 2,
      leftLine.y,
      block_width,
      staffLinesTotalHeight,
      0,
    );
    rightBlock.alpha = 0;
    grandStaff.add(rightBlock);
    const rightLine = this.add.rectangle(
      this.config!.width! - rightBlock.displayWidth * 2,
      leftLine.y,
      this.config!.line_thickness,
      staffLinesTotalHeight,
      0,
    );
    rightLine.alpha = 0;
    leftLine.alpha = 0;
    rightLine.height = 0;
    rightBlock.height = 0;
    leftLine.height = 0;
    grandStaff.add(rightLine);
    this.tweens.add({
      targets: leftLine,
      height: staffLinesTotalHeight,
      alpha: 1,
      duration: 1000,
    });
    [rightLine, rightBlock].forEach((i, idx) =>
      this.tweens.add({
        targets: i,
        height: staffLinesTotalHeight,
        alpha: 1,
        duration: 1000,
        ease: "Power1",
        delay: 1000 + idx * 100,
      }),
    );
    const noteOffset =
      Math.max(trebleClef.displayWidth, bassClef.displayWidth) +
      this.config!.clef_padding! +
      this.config!.first_note_offset!;
    const noteGap =
      (this.config!.width! - (rightBlock.x - rightLine.x) * 2) /
      Math.max(trebleNotes.length + 1, bassNotes.length + 1);
    this.trebleNotes = trebleNotes.map(([note, length], idx) => {
      const octave = getOctave(note);
      const noteGraphic = new NoteObject(
        this,
        note,
        length,
        octave,
        noteOffset + idx * noteGap,
        this.config!,
      );
      const y = noteGraphic.y;
      noteGraphic.y = y + 100 * window.devicePixelRatio;
      noteGraphic.alpha = 0;
      noteGraphic.iterate(
        (obj: Phaser.GameObjects.Image | Phaser.GameObjects.Rectangle) => {
          obj.alpha = 0;
        },
      );
      grandStaff.add(noteGraphic);
      this.tweens.add({
        targets: noteGraphic,
        y,
        alpha: 1,
        delay: 700 + idx * 100,
        ease: "Power2",
        onUpdate: (progress) => {
          (progress.targets[0] as Phaser.GameObjects.Container).iterate(
            (obj: Phaser.GameObjects.Image | Phaser.GameObjects.Rectangle) =>
              (obj.alpha = progress.progress),
          );
        },
      });
      return noteGraphic;
    });
    const cursorHeight = staffLinesTotalHeight * 1.4;
    const cursorWidth = cursorHeight / 10;
    const cursorData = this.trebleNotes.map((x, id) => ({
      x: x.getX() + grandStaff.x,
      id,
    }));
    cursorData.sort((a, b) => a.x - b.x);
    this.cursor = new NoTimeCursor(
      this,
      this.trebleNotes[0].getX() + grandStaff.x,
      grandStaff.y - staffLinesTotalHeight * 0.9,
      cursorWidth,
      cursorHeight,
      cursorData,
      rightLine.x + grandStaff.x - cursorWidth / 2,
      this,
      this.onDone,
      this,
    );
  }

  midiOn({ key }: { key: number }) {
    console.debug("exercise: key: ", key);
    if (this.listening) this.cursor?.next(key);
  }

  onDone() {
    //alert('finish');
    console.log("exercise: done", this.currentObjective);
    this.transition(true);
    super.passObjective(
      this.currentObjective === this.config!.objectives.length - 1,
    );
  }

  create() {
    EventBus.emit("current-scene-ready", this);
  }

  onExercisePass() {
    console.log("exercise: passed");
    //this.transition(true);
    super.onExercisePass();
  }

  transition(out?: boolean, onDone?: () => void, scope?: object) {
    this.tweens.add({
      targets: this.cameras.main.getPostPipeline("Dissolve") as Dissolve,
      progress: 0,
      duration: 1000,
    });
  }

  init(config: Config) {
    console.log("show me");
    super.init(config);
    this.config = DefaultConfig(config);
    if (this.config.centre_align_horizontal) {
      this.config.padding_x =
        this.cameras.main.width / 2 - this.config.width! / 2;
    }
    console.debug(this.config);
    let delay = this.config.firstObjectiveAppearDelay ?? 0;
    this.appearanceDelay = this.time.delayedCall(
      delay,
      () => this.createGrandStaff(this.config!.objectives[0].trebleNotes),
      [],
      this,
    );
    this.cameras.main.setPostPipeline("Dissolve");
    (this.cameras.main.getPostPipeline("Dissolve") as Dissolve).progress = 1;
    ListenForReactEvent(ListenUpdateObjective(this.updateObjective), this);
    NotifyReact(ShowExercise());
  }
}
