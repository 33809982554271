import Phaser from "phaser";
import { colors } from "../config";
import RoundedRect from "./RoundedRect";
import TimeSignature from "../../Models/TimeSignature";
import EventStream, {
  TimingOffsets,
  TimingOffsetsConfig,
  NoteEvent,
} from "Models/EventStream";
import { ProgressState } from "./Progress";
import IPlayable from "./Playable";
import { Iterator } from "Models/Phrase";
import { PrerenderedGraphics, PrerenderedPhraseData } from "Types";
import { INoteGraphicsDatabase } from "Phaser/Scenes/StaffExercise";
import ITimeKeeper from "Models/ITimeKeeper";
import TimeKeeper from "Models/TimeKeeper";
import { NoteType } from "opensheetmusicdisplay";

export class IteratorPhaser extends Iterator {
  peekNext(): PrerenderedPhraseData | undefined {
    if (this.indx >= this.cursorData.length - 1) return undefined;
    else {
      return this.cursorData[this.indx + 1];
    }
  }
}

export class EventStreamPhaser extends EventStream {
  database: INoteGraphicsDatabase;
  scene: Phaser.Scene;
  constructor(
    timeKeeper: ITimeKeeper,
    offsetsConfig: TimingOffsetsConfig,
    database: INoteGraphicsDatabase,
    scene: Phaser.Scene,
    weightedAccuracyDivisions: number = 1,
    weightedAccuracyHitVelocity: number = 0.025,
    weightedAccuracyMissVelocity: number = 0.1,
    useRepertoireMarkAccuracy: boolean = false,
  ) {
    super(
      timeKeeper,
      offsetsConfig,
      weightedAccuracyDivisions,
      weightedAccuracyHitVelocity,
      weightedAccuracyMissVelocity,
      useRepertoireMarkAccuracy,
    );
    this.database = database;
    this.scene = scene;
  }
  protected pulseMatchedNote(note: NoteEvent, colorClass: string) {
    console.log("correct");
    let n = this.database.getNoteById(note.graphics[0].staveNote.id)!;
    let color = colors.green;
    /*switch (colorClass) {
      case 'correct':

        break;
    }*/
    const circle = this.scene.add.circle(
      n.getPosition().x,
      n.getPosition().y,
      0,
      color,
      1,
    );
    this.scene.tweens.add({
      targets: circle,
      radius: 35,
      alpha: 0,
      duration: 500,
      onComplete: (tween) => {
        (tween.targets[0] as any).destroy();
      },
      ease: "Power2",
    });
  }
  protected makeNoteMistimed = (note: PrerenderedGraphics[]) => {
    note.forEach((n) =>
      this.getNoteObject(n.staveNote.id)?.setTimingState(
        ProgressState.LateStart,
      ),
    );
  };
  protected makeNoteWrong = (note: PrerenderedGraphics[]) => {
    note.forEach((n) =>
      this.getNoteObject(n.staveNote.id)?.setTimingState(ProgressState.Missed),
    );
  };
  protected makeNoteCorrect = (note: PrerenderedGraphics[]) => {
    console.debug("marking correct: ", note);
    note.forEach((n) =>
      this.getNoteObject(n.staveNote.id)?.setTimingState(ProgressState.Ended),
    );
  };
  public getNoteObject(id: string): IPlayable | undefined {
    return this.database.getNoteById(id);
  }
  doUpdate(cursorX: number) {
    super.update();
  }
}

interface SceneWithPhrases extends INoteGraphicsDatabase, Phaser.Scene {}

export type StartTiming = "early" | "perfect" | "late";

const durationInBeatsToMs = (beats: number, tempo: number) => {
  const beatLengthInMs = (60 / tempo) * 1000;
  return beats * beatLengthInMs;
};

export type StaffNote = {
  position: { x: number; y: number };
  durationInMs: number;
  // duration relative to a whole note, i.e, 1/4 for quarter notes,
  // 1/2 for half notes and so on
  timestamp: number;
  durationRelative: number;
  playable?: IPlayable;
  clef?: "treble" | "bass";
};

/*export type NoteEvent = {
  note: StaffNote;
  timestamp: number;
  offsetTime: TimingOffsets;
  onsetTime: TimingOffsets;
};*/

export type NoteStream = StaffNote[];

/// Describes the cursor game object and all related logic for moving it around the track.
export default class Cursor extends RoundedRect {
  lastNoteLength: number = 0;
  lastTimestamp: number;
  lastLeft: number;
  /// Handle to the tween that is spawned for moving the cursor across the screen.
  clickHandle: Phaser.Tweens.Tween | null = null;
  /// Metronome handle
  //clickHandle: Phaser.Time.TimerEvent | null = null;
  click: Phaser.Sound.BaseSound;
  exercise: Phaser.Scene;
  tempo: number;
  started: boolean = false;
  isMoving = false;

  nextTick: number = 0;
  lastTick: number = 0;

  msPerTick: number;
  timeSignature: TimeSignature;
  lastTimeUpdate: number = 0;
  lastNoteTime: number = 0;

  paused = false;
  wasPausedLastFrame = false;
  resumedThisFrame = false;
  eventStream: EventStreamPhaser;
  cursorData: IteratorPhaser;
  timeKeeper: TimeKeeper;
  currentNoteIndex: number = -1;

  onDone?: () => void;
  onDoneCbScope: any;

  audioPaused: boolean = false;

  runTime: number = 0;

  offsetsConfig: TimingOffsetsConfig;
  notesRemaining: NoteEvent[] = [];
  notesPlaying: NoteEvent[] = [];
  notesPassed: NoteEvent[] = [];

  beatNo: number = 0;
  isLastTick = false;

  // Debugging
  indicator: Phaser.GameObjects.Rectangle;

  constructor(
    scene: SceneWithPhrases,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
    timeSig: TimeSignature,
    bpm: number,
    cursorData: IteratorPhaser,
  ) {
    super(scene, x, y, width, height, radius, colors.cursor, 0);
    this.indicator = scene.add.rectangle(0, 0, 40, 20, 0xff0000);
    this.indicator.setOrigin(0, 0);
    this.indicator.alpha = 0;
    this.offsetsConfig = new TimingOffsetsConfig();
    this.exercise = scene;
    this.alpha = 0;
    this.tempo = bpm;
    //this.draw();
    this.click = this.scene.sound.add("click");
    this.msPerTick = (60 / this.tempo) * 1000;
    console.log("bpm: ", bpm);
    scene.sound.pauseOnBlur = true;
    //this.cursorData = cursorData;
    this.timeSignature = timeSig;
    //this.addNoteEvents(cursorData);
    //console.debug(cursorData);
    //this.setX(cursorData[0].position.x - width / 2);
    this.timeKeeper = new TimeKeeper();
    this.timeKeeper.setTimeSignatures(0, [timeSig]);
    this.eventStream = new EventStreamPhaser(
      this.timeKeeper,
      new TimingOffsetsConfig(),
      scene,
      scene,
    );
    this.eventStream.addEvents2(cursorData, 0);
    cursorData.resetIterator();
    this.cursorData = cursorData;
    this.lastTimestamp = this.cursorData.currentTimeStamp;
    this.lastLeft = this.cursorData.left()!;
    this.setX(this.lastLeft - this.width / 2);
  }

  addNoteEvents(cursorData: NoteStream) {
    // the sequence of notes passed to cursor data are assumed
    // to arranged in increasing order of their timestamps
    const events = [];
    for (const note of cursorData) {
      const noteDuration = note.durationRelative;
      console.debug(noteDuration);
      const onsetTimestamp = note.timestamp;
      const offsetTimestamp = onsetTimestamp + note.durationRelative;
      const correctNoteOffsetStartTimestamp =
        onsetTimestamp + this.calculateCorrectNoteOffMin(noteDuration);
      const startAllowance = 0.25;
      const endAllowance = 0.25;
      const noteOnTimestamps = new TimingOffsets(
        onsetTimestamp === 0
          ? -0.2
          : onsetTimestamp -
            startAllowance * this.offsetsConfig.correctNoteOnsetStartPct,
        onsetTimestamp +
          endAllowance * this.offsetsConfig.correctNoteOnsetEndPct,
        onsetTimestamp === 0
          ? -0.2
          : onsetTimestamp -
            startAllowance * this.offsetsConfig.mistimedNoteOnsetStartPct,
        onsetTimestamp +
          endAllowance * this.offsetsConfig.mistimedNoteOnsetEndPct,
      );
      const noteOffTimestamps = new TimingOffsets(
        correctNoteOffsetStartTimestamp,
        offsetTimestamp +
          endAllowance * this.offsetsConfig.correctNoteOffsetEndPct,
        correctNoteOffsetStartTimestamp - noteDuration * 0.2,
        offsetTimestamp +
          endAllowance * this.offsetsConfig.mistimedNoteOffsetEndPct,
      );
      events.push({
        note,
        timestamp: onsetTimestamp,
        offsetTime: noteOffTimestamps,
        onsetTime: noteOnTimestamps,
      });
    }
    /*this.notesRemaining = events.sort(
      (a, b) =>
        a.onsetTime.mistimedStartTimestamp - b.onsetTime.mistimedStartTimestamp
    );*/
    console.debug(this.notesRemaining);
  }

  pauseAudio() {
    this.audioPaused = true;
    if (this.click) {
      (this.click as any).setMute(true);
    }
  }

  resumeAudio() {
    this.audioPaused = false;
    (this.click as any).setMute(false);
  }

  /// Moves the cursor back to the starting position and stops movement
  resetHead() {
    this.currentNoteIndex = -1;
    this.isMoving = false;
    this.setX(this.cursorData.left()! - this.width / 2);
    //this.setX(this.cursorData[0].position.x - this.width / 2);
    this.runTime = 0;
    this.draw();
  }

  pause(): void {
    this.wasPausedLastFrame = true;
    this.paused = true;
    this.timeKeeper.pause();
  }

  resume(): void {
    console.log("resume");
    this.resumedThisFrame = true;
    this.paused = false;
    if (this.isMoving)
      this.timeKeeper.unpause(this.tempo, 0, 0, NoteType._64th);
  }

  /* Start moving the cursor. Calls `cb` once the cursor reaches the end. `cb` is bound
   * to the provided `cbScope` object.
   */
  tryStart(cb?: () => void, cbScope?: object): [StartTiming, number] {
    this.isLastTick = false;
    console.log("woo");
    this.onDone = cb;
    this.onDoneCbScope = cbScope;
    const timeDiff = this.lastTimeUpdate + 16.6 - this.lastTick;
    const relTimeDiff = timeDiff / this.msPerTick;
    console.log("time: ", relTimeDiff);
    if (relTimeDiff > 0.2 && relTimeDiff < 0.9) {
      if (relTimeDiff > 0.8) return ["early", timeDiff];
      else return ["late", timeDiff];
    }
    this.lastNoteTime = this.lastTimeUpdate;
    this.resetHead();
    this.isMoving = true;
    this.runTime = 0;
    this.setAlpha(colors.cursorAlpha);
    this.timeKeeper.unpause(this.tempo, 0, 0, NoteType._64th);
    this.lastTick = this.timeKeeper.getAudioContextTime() * 1000 + 200;
    console.log("perfect");
    return ["perfect", timeDiff];
  }

  /// Stops the cursor's movement. This does not reset the cursor back to the start.
  stop() {
    this.isLastTick = true;
    console.log("stop");
    this.isMoving = false;
    this.started = false;
    this.lastTick = 0;
    this.wasPausedLastFrame = false;
    this.resumedThisFrame = false;
    this.onDone?.call(this.onDoneCbScope);
  }

  tick(): void {
    if (!this.audioPaused && !this.isLastTick) {
      this.click.play();
    }
    this.setAlpha(colors.cursorAlpha);
  }

  getRelativeTimestamp(): number {
    const durationPerMeasure = (60 / this.tempo) * 4;
    return this.runTime / 1000 / durationPerMeasure;
  }

  checkTiming(_data?: any): StartTiming {
    const relTimeDiff =
      (this.lastTimeUpdate + 16.6 - this.lastTick) / this.msPerTick;
    if (relTimeDiff > 0.15 && relTimeDiff < 0.85) {
      if (relTimeDiff > 0.6) return "early";
      else return "late";
    } else return "perfect";
  }

  onHoldNote(_data?: any) {
    if (!this.isMoving) return;
    console.debug("played note: ", _data);
    this.eventStream.handleNoteOnEvent(_data as number, 0);
  }

  onReleaseNote(_data?: any) {
    if (!this.isMoving) return;
    console.debug("played note: ", _data);
    this.eventStream.handleNoteOffEvent(_data as number);
  }

  lerp = (a: number, b: number, t: number): number => a * (1 - t) + b * t;

  clamp = (a: number, min: number, max: number): number => {
    if (a < min) return min;
    else if (a > max) return max;
    return a;
  };

  next() {
    if (this.cursorData.EndReached) {
      this.resetHead();
      this.resetEvents();
      this.onDone?.call(this.onDoneCbScope);
    } else {
      //this.currentNoteIndex++;
    }
  }

  // This function is absolute madness right now
  // I will refactor it later TM
  update(time: number): void {
    if (!this.started) return;
    const currentTimestamp = this.timeKeeper.audioTimeToMeasureTimestamp();
    //(this.exercise as any).debugLog(`currentTime: ${currentTimestamp}`);
    if (!this.started) return;
    let pauseDuration = 0;
    if (this.wasPausedLastFrame && this.resumedThisFrame) {
      pauseDuration = time - this.lastTimeUpdate;
      this.lastTick += pauseDuration;
      this.lastNoteTime += pauseDuration;
      this.wasPausedLastFrame = false;
      this.resumedThisFrame = false;
    }
    if (this.lastTick === 0) this.lastTick = time;
    let diff = time - this.lastTick;
    //let diff = time - this.lastTick;
    if (!this.paused && this.isMoving) {
      diff = this.timeKeeper.getAudioContextTime() * 1000 - this.lastTick;
      //(this.exercise as any).debugLog(`${diff}`);
      this.runTime += time - this.lastTimeUpdate - pauseDuration;
      //const nextNote = this.notesRemaining[0];
      const nextTimestamp = this.cursorData.EndReached
        ? this.lastTimestamp + this.lastNoteLength
        : this.cursorData.currentTimeStamp;
      const nextNotes = this.cursorData.EndReached
        ? undefined
        : this.cursorData.CurrentVoiceEntries;
      //console.debug(this.notesRemaining, nextNote);
      if (currentTimestamp >= nextTimestamp && !nextNotes) {
        this.stop();
        return;
      }
      if (nextNotes && currentTimestamp >= nextTimestamp) {
        nextNotes?.forEach((entry) => {
          entry.Notes.forEach(
            (note) =>
              !note.isRest &&
              this.eventStream
                .getNoteObject(note.graphics[0].staveNote.id)
                ?.setFocus(true),
          );
        });
        this.lastTimestamp = this.cursorData.currentTimeStamp;
        this.lastLeft = this.x + this.width / 2;
        this.lastNoteLength = this.cursorData.CurrentVoiceEntries[0].Notes.sort(
          (x, y) => y.length - x.length,
        ).at(0)!.length;
        this.cursorData.next();
      }
    }
    const t = this.isMoving
      ? this.timeKeeper.getAudioContextTime() * 1000
      : time;
    if (diff >= this.msPerTick) {
      this.tick();
      const delay = diff - this.msPerTick;
      this.lastTick = t - delay;
    }
    const prog = diff / this.msPerTick;
    if (!this.isMoving)
      this.setAlpha(this.lerp(colors.cursorAlpha, 0, this.clamp(prog, 0, 1)));
    this.lastTimeUpdate = time;
    if (this.isMoving) {
      this.indicator.setX(this.lastLeft);
      this.indicator.displayWidth = this.cursorData.left()! - this.lastLeft;
      let now = this.timeKeeper.audioTimeToMeasureTimestamp();
      let elapsed = now - this.lastTimestamp;
      // use left() from iterator to get end of phrase position
      const target = this.cursorData.EndReached
        ? this.lastNoteLength + this.lastTimestamp
        : this.cursorData.currentTimeStamp;
      /*if (this.currentNoteIndex < 0) {
        throw new Error('current not index is 0');
      }*/
      const t = elapsed / (target - this.lastTimestamp);
      const pos = this.lerp(
        this.lastLeft,
        this.cursorData.left()!,
        this.clamp(t, 0, 1),
      );
      /*(this.exercise as any).debugLog(
        `t: ${t}, elapsed: ${elapsed}, last: ${this.lastTimestamp}
        now: ${now}, currentTime: ${target}, left: ${this.cursorData.left()}`,
      );*/
      this.eventStream.doUpdate(pos + this.width / 2);
      /*this.cursorData[this.currentNoteIndex].playable?.update(
        pos + this.width / 2
      );*/
      this.setX(pos - this.width / 2);
    }
  }

  waitForInput(): void {
    this.tick();
    this.started = true;
    this.isLastTick = false;
  }

  resetEvents() {
    this.notesRemaining.clear();
    this.notesPassed.clear();
    this.notesPlaying.clear();
    this.eventStream.reset();
    this.eventStream.addEvents2(this.cursorData, 0);
    this.cursorData.resetIterator();
    //this.addNoteEvents(this.cursorData);
  }

  /// Resets the cursor. Moves it all the way back to start. And clears all tweens in charge of moving the cursor.
  reset() {
    this.resetEvents();
    this.resetHead();
  }

  destroy(): void {
    console.log("cursor removed");
    this.started = false;
    this.cursorData.clearCache();
    this.cursorData.resetIterator();
    this.eventStream.reset();
    this.isMoving = false;
    this.currentNoteIndex = -1;
    super.destroy();
  }

  isActive = (): boolean => this.started;

  // lifted from /Models/EventStream.ts
  private calculateCorrectNoteOffMin(noteDuration: number) {
    if (noteDuration <= 1.0 / 4.0) {
      return noteDuration * 0.5;
    }
    // If duration is between a quarter note and half note, use this block.
    else if (noteDuration > 1.0 / 4.0 && noteDuration < 2.0 / 4.0) {
      const nRange = 2.0 / 4.0 - 1.0 / 4.0;
      // Scale from 0.5 to 0.625 of the note, on a nearly linear curve
      let nScale = 1.0 - (2.0 / 4.0 - noteDuration) / nRange;
      // nScale is normalized from 0..1; putting that on a curve nScale^0.6
      // lets a noteDuration=1.5 have minimum offset of just under 1 beat.
      // Adjust the following line to adjust how "aggressive" scaling between 1 and 2 is.
      // Use graphtoy.com to visualize the curve you're adjusting.
      nScale = Math.pow(nScale, 0.6);
      return 1.0 / 8.0 + 0.1875 * nScale;
    } else {
      return noteDuration - 1.0 / 4.0 + 1.0 / 16.0;
    }
  }
}
